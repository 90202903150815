import React, { useContext, useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "./Context/UserContext";

const LoginRoute = ({ component }) => {
  const { isLoggedIn } = useContext(UserContext);

  const auth = isLoggedIn();

  // if (component.type.name === "Login") {
  //   return auth.login ? <Navigate to="/" /> : <Navigate to="/login" />;
  // } else {
  //   return auth.login ? component : <Navigate to="/login" />;
  // }

  return auth.login ? <Navigate to="/" /> : component;
};

export default LoginRoute;
