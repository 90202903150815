import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { NewPassword } from "../api/changePassword";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewPasswordForm = ({ userId, resetToken }) => {
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const navigate = useNavigate();

  const notify = (res) => {
    if (res === "updated") {
      toast.success("Password Updated. Redirecting to Login");
    } else if (res === "incomplete") {
      toast.error("Please enter complete details");
    } else if (res === "notMatched") {
      toast.error("Password Dont Match");
    } else {
      toast.error("Oops somethin went wrong");
    }
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    console.log(newPassword, userId, resetToken);
    if (newPassword && confirmNewPassword) {
      if (newPassword === confirmNewPassword) {
        if (userId && resetToken) {
          NewPassword(userId, newPassword, resetToken)
            .then((res) => {
              if (res) {
                console.log(res);
                if (res.status === 200 && res.data.updated === true) {
                  notify("updated");
                  setTimeout(() => {
                    navigate("/login", { replace: true });
                  }, 3000);
                } else {
                  notify("error");
                }
              } else {
                notify("error");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        notify("notMatched");
      }
    } else {
      notify("incomplete");
    }
    e.preventDefault();
  };

  return (
    <div>
      <form className="loginForm" autoComplete="off">
        <div style={{ width: "62%", margin: "15px auto" }}>
          <img style={{ width: "100%" }} src="/images/forgot-pass.png" />
        </div>

        {/* <input type="text" placeholder='Username' />
                <br /> */}

        <input
          type="password"
          placeholder="New Password"
          onChange={handleNewPassword}
        />
        <br />

        <input
          type="password"
          placeholder="Confirm New Password"
          onChange={handleConfirmNewPassword}
        />

        <button type="submit" onClick={handleSubmit}>
          <img style={{ width: "100%" }} src="/images/submit-btn.png" />
        </button>
        <button type="submit">
          <Link to="/">
            <img style={{ width: "100%" }} src="/images/back-btn.png" />
          </Link>
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default NewPasswordForm;
