import React, { useState, useEffect, useContext } from "react";

import ChooseOpponentShuffle from "./ChooseOpponentShuffle";

import { useNavigate } from "react-router-dom";
import { RoomContext } from "../Context/RoomContext";
import { UserContext } from "../Context/UserContext";

import { Row, Col, Container } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../index.css";

const ChooseOpponent = () => {
  let userContext = useContext(UserContext);
  const roomContext = useContext(RoomContext);
  const {
    opponentJoined,
    opponentId,
    opponentLeft,
    opponentName,
    opponentAvatar,
    leaveBeforeGameStart,
    setRoundOne,
    room,
    setRoom,
    joinRoom,
    singlePlayer,
    setSinglePlayer,
    selectedCategory,
    joinSinglePlayerRoom,
    startRoundOne,
  } = roomContext;
  const { userName } = userContext;

  const [searchTimeout, setSearchTimeout] = useState(false);

  const [btnDisable, setBtnDisable] = useState(true);

  const [timer, setTimer] = useState(25);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const avatar = userData && userData.avatar;

  let navigate = useNavigate();

  const notify = (response) => {
    if (response === "turnChanged") {
      toast.success("Opponent's Turn Now");
    } else if (response === "left") {
      toast.warning("Opponent Left");
    } else if (response === "timeout") {
      toast.warning(
        <center>
          <h4>Timeout</h4>

          <p>Click Single Player to play solo</p>

          <p>Click Cancel to go on category screen</p>

          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "20px", marginRight: "5px" }}
            onClick={handleCancel}
          >
            cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "20px", marginLeft: "5px" }}
            onClick={startSinglePlayer}
          >
            Single Player
          </button>
        </center>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
    } else {
      toast.error("Some thing went wrong");
    }
  };

  useEffect(() => {
    setBtnDisable(true);
  }, []);

  useEffect(() => {
    if (opponentLeft === true) {
      notify("left");
      setTimer(25);
      joinRoom(selectedCategory.category);
    }
  }, [opponentLeft]);

  useEffect(() => {
    if (searchTimeout) {
      notify("timeout");
      // setTimeout(() => {
      //   if (!singlePlayer) {
      //     navigate("/select-category", { replace: true });
      //   }
      // }, 6000);
      leaveBeforeGameStart();
      setRoom(null);
    }
  }, [searchTimeout]);

  // let data = [
  //   { img: "/images/card1.png" },
  //   { img: "/images/card2.png" },
  //   { img: "/images/card3.png" },
  //   { img: "/images/card4.png" },
  // ];

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCounter((counter + 1) % data.length);
  //   }, 50);
  // }, [counter]);

  // useEffect(() => {
  //   if (opponentJoined === false) {
  //     notify("left");
  //     navigate("/select-category", { replace: true });
  //   }
  // }, [opponentJoined]);

  useEffect(() => {
    if (singlePlayer && startRoundOne) {
      navigate("/game", { replace: true });
    }
  }, [startRoundOne]);

  const startGame = () => {
    if (opponentJoined) {
      setRoundOne(true);
      setTimeout(() => {
        navigate("/game", { replace: true });
      }, 1000);
    }
  };

  const startSinglePlayer = () => {
    leaveBeforeGameStart();
    // setRoom(null);
    setSinglePlayer(true);
    toast.loading("Launching Single Player Mode");
    joinSinglePlayerRoom(selectedCategory.category);
    console.log(selectedCategory);
  };

  const handleCancel = () => {
    leaveBeforeGameStart();
    // setRoom(null);
    navigate("/select-category");
    console.log("cancel");
  };

  const timeOut = () => {
    if (!opponentJoined && !opponentId) {
      console.log(opponentJoined);
      setSearchTimeout(true);
      // leaveBeforeGameStart();
      // setTimeout(() => {
      //   handleCancel();
      // }, 3000);
    }
  };

  setTimeout(() => {
    setBtnDisable(false);
  }, 3000);

  useEffect(() => {
    setTimeout(() => {
      if (timer === 0) {
        timeOut();
      } else if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
  }, [timer]);

  console.log(timer);

  return (
    <div className="chooseOpponent">
      <Container
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Row>
          <Col
            md={7}
            sm={12}
            style={{ height: "80vh" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Row
              style={{ marginBottom: "10px" }}
              className="category-container"
            >
              <img style={{ width: "60%" }} src="/images/Time-Quest.png" />
            </Row>

            <Row
              style={{ marginBottom: "10px" }}
              className="category-container"
            >
              <h2
                style={{
                  color: "goldenrod",
                  fontSize: "3.5vmin",
                  marginBottom: "8vmin",
                  textAlign: "center",
                }}
                className="vs"
              >
                WAITING FOR OPPONENT
              </h2>
            </Row>

            <Row>
              <div className="opponents-list">
                <Col>
                  <div
                    // style={{ marginRight: "-20px" }}
                    className="avatar-container"
                  >
                    <img
                      style={{ width: "50%", zIndex: "1" }}
                      className="avatar-frame-choose-opponent"
                      src="/images/choose-opponent-frame.png"
                      alt="avatar-frame"
                    />
                    <img
                      // style={{ marginTop: "0vmin" }}
                      className="player-image-choose-opponent"
                      src={avatar}
                      alt="player-avatar"
                    />
                    <h3
                      style={{
                        marginBottom: "-20px",
                        fontSize: "4vmin",
                        color: "goldenrod",
                      }}
                      className="vs"
                    >
                      {userName}
                    </h3>
                    {/* {opponentJoined && (
                      <button
                        className="chooseOpponent-btn"
                        onClick={startGame}
                      >
                        START
                      </button>
                    )} */}
                  </div>
                </Col>

                <Col>
                  <div className="avatar-container">
                    <h1
                      style={{
                        fontSize: "10vmin",
                        color: "goldenrod",
                        margin: "5vmin 2vmin 0px auto",
                      }}
                      className="vs"
                    >
                      VS
                    </h1>
                  </div>
                </Col>

                <Col>
                  <div className="avatar-container">
                    <img
                      style={{ width: "200%" }}
                      className="avatar-frame-choose-opponent"
                      src="/images/choose-opponent-frame.png"
                      alt="avatar-frame"
                    />
                    {/* {console.log("iMAGE dATA: ", imgData)}
                    <img
                      className="player-image-choose-opponent"
                      src={opponentJoined ? opponentAvatar : imgData}
                      alt="player-avatar"
                    /> */}
                    <ChooseOpponentShuffle
                      opponentJoined={opponentJoined}
                      opponentAvatar={opponentAvatar}
                      startRoundOne={startRoundOne}
                    />
                    <h3
                      style={{
                        marginBottom: "-20px",
                        fontSize: "4vmin",
                        color: "goldenrod",
                      }}
                      className="vs"
                    >
                      {opponentJoined && startRoundOne ? opponentName : "?"}
                    </h3>
                    {/* <button className="chooseOpponent-btn" onClick={startGame}>
                      CANCEL
                    </button> */}
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "3vmin",
                }}
              >
                {opponentJoined && startRoundOne && (
                  <button className="chooseOpponent-btn" onClick={startGame}>
                    Start
                  </button>
                )}
                <button
                  disabled={btnDisable}
                  className="chooseOpponent-btn"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  disabled={opponentJoined || btnDisable}
                  className="chooseOpponent-btn"
                  onClick={startSinglePlayer}
                >
                  Single Player
                </button>
              </Col>
            </Row>
          </Col>

          <Col md={5} className="column"></Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default ChooseOpponent;
