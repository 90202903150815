import React, {useContext, useState, useEffect} from "react";
import {RoomContext} from "../Context/RoomContext";
import {useNavigate} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Game.css";

// let categories = [
//   {
//     category: "American Revolution",
//     img_url: "/images/card-slot.png",
//   },
//   {
//     category: "American History",
//     img_url: "/images/card-slot.png",
//   },
//   {
//     category: "Age Of Exploration",
//     img_url: "/images/card-slot.png",
//   },
//   {
//     category: "Ancient Rome",
//     img_url: "/images/card-slot.png",
//   },
//   {
//     category: "The Crusades",
//     img_url: "/images/card-slot.png",
//   },
//   {
//     category: "World War II",
//     img_url: "/images/card-slot.png",
//   },
// ];

const SelectCategory = () => {
  const [selectedCategoryByUser, setSelectedCategoryByUser] = useState();

  const [categories, setCategories] = useState();

  let roomContext = useContext(RoomContext);

  const {joinRoom, joinSinglePlayerRoom, setSelectedCategory} = roomContext;

  let navigate = useNavigate();

  const notify = response => {
    if (response === "select") {
      toast.error("Please Select A Topic");
    }
  };

  useEffect(() => {
    window.onbeforeunload = undefined;
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    // console.log("userData", userData);

    const accessToken = userData && userData.accessToken;
    const schoolId = userData && userData.schoolId;

    var axios = require("axios");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/category/${schoolId}`,
      headers: {token: accessToken},
    };

    axios(config)
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          // console.log("RESPONSE", response.data);
          if (response.data.data && response.data.data.length > 0) {
            setCategories(response.data.data);
          } else if (response.data.data && response.data.data.length === 0) {
            setCategories([]);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.setItem("userData", null);
          // navigate("/", { replace: true });
          window.location.href = "/";
        }
      });
  }, []);

  const handleBack = () => {
    navigate("/", {replace: true});
  };

  const startGame = slectectedItem => {
    if (slectectedItem) {
      setSelectedCategory(slectectedItem);
      // joinSinglePlayerRoom(slectectedItem.category);
      joinRoom(slectectedItem.category);
      navigate("/choose-opponent", {replace: true});
    } else {
      console.log("Please Select A Category");
      notify("select");
    }
  };

  return (
    <div className="selectCategory">
      <Container
        style={{height: "100vh"}}
        className="d-flex justify-content-center align-items-center"
      >
        <Row style={{marginTop: "2vmin"}}>
          <Col
            lg={7}
            md={7}
            xs={12}
            style={{height: "80vh"}}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Row style={{marginBottom: "10px"}} className="category-container">
              <img style={{width: "60%"}} src="/images/Time-Quest.png" />
            </Row>
            <Row style={{marginBottom: "10px"}} className="category-container">
              <img
                style={{width: "90%", height: "9vmin", margin: "10px auto"}}
                src="/images/choose-topic.png"
              />
            </Row>
            {categories ? (
              <Row className="categoriesList">
                {categories && categories.length > 0 ? (
                  categories.map((item, index) => {
                    const imageObj = {
                      src: `${process.env.REACT_APP_IMAGES_URL}${item.img_url}`,
                      alt: item.cateopry,
                    };
                    return (
                      <Col md={4} xs={6} key={index}>
                        <div
                          className={"changeCursor"}
                          // style={{ border: "1px solid black" }}
                          // style={{selectedCategoryByUser ? border}}
                          onClick={() => setSelectedCategoryByUser(item)}
                        >
                          {/* <img
                            className={
                              selectedCategoryByUser &&
                              item.id === selectedCategoryByUser.id
                                ? "categoryborder categoryImg"
                                : "categoryImg"
                            }
                            // style={{
                            //   width: "100%",
                            //   height: "15vw",
                            //   objectFit: "cover",
                            //   borderRadius: "10px",
                            // }}
                            src={`${process.env.REACT_APP_IMAGES_URL}${item.img_url}`}
                            // loading="lazy"
                            alt={item.category}
                          /> */}
                          {/* <div
                            className={
                              selectedCategoryByUser &&
                              item.id === selectedCategoryByUser.id
                                ? "categoryborder categoryImg"
                                : "categoryImg"
                            }
                          > */}
                          <LazyLoadImage
                            className={
                              selectedCategoryByUser &&
                              item.id === selectedCategoryByUser.id
                                ? "categoryborder categoryImg"
                                : "categoryImg"
                            }
                            width="100%"
                            height="categoryImg"
                            alt={item.category}
                            effect="blur"
                            src={`${process.env.REACT_APP_IMAGES_URL}${item.img_url}`}
                            // placeholderSrc="/images/placeholder.png"
                          />
                          {/* </div> */}
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textAlign: "center",
                              fontSize: 20,
                              textTransform: "capitalize",
                              fontWeight: "bold",
                              color: "goldenrod",
                            }}
                          >
                            {item.category}
                          </p>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Col md={4} xs={6}>
                    {categories && categories.length === 0 && (
                      <h2 style={{marginTop: "20vmin"}}>No Category Found</h2>
                    )}
                  </Col>
                )}
              </Row>
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{height: "40vh"}}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            <Row className="category-container">
              <button
                onClick={handleBack}
                style={{
                  width: "25vmin",
                  marginTop: "30px",
                  border: "none",
                  background: "transparent",
                }}
              >
                <img style={{width: "100%"}} src="/images/back-btn.png" />
              </button>
              <button
                onClick={() => startGame(selectedCategoryByUser)}
                style={{
                  width: "25vmin",
                  marginTop: "30px",
                  border: "none",
                  background: "transparent",
                }}
              >
                <img style={{width: "100%"}} src="/images/submit-btn.png" />
              </button>
            </Row>
          </Col>
          <Col md={5} lg={5}></Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default SelectCategory;
