import React, { useContext } from "react";
import { RoomContext } from "../Context/RoomContext";
import { useDrag } from "react-dnd";
import { css } from "aphrodite";
import { styles } from "./Animations";

import { Col } from "react-bootstrap";
import "./Game.css";

const Card = ({
  id,
  name,
  url,
  backUrl,
  date,
  dateType,
  canDrop,
  roundOneCards,
  roundTwoCards,
  insertCard,
  index,
}) => {
  const roomContext = useContext(RoomContext);
  const { setCheckDragging } = roomContext;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "image",
      item: {
        id: id,
        name: name,
        url: url,
        backUrl: backUrl,
        date: date,
        dateType: dateType,
        canDrop: canDrop,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [roundOneCards, roundTwoCards]
  );

  let arrIndex = roundOneCards ? roundOneCards.length - 1 : "";
  let trimmedName = name?.length > 28 ? name.substring(0, 25) + "..." : name;

  return (
    <Col className="cardContainer">
      <div
        className={
          insertCard && index === arrIndex ? `` : `${css(styles.swashIn)}`
        }
      >
        <img
          className="card"
          ref={drag}
          src={url}
          alt={name}
          title={name}
          style={{
            cursor: isDragging ? `grabbing ` : "",
            background: "transparent",
          }}
        />
        <p className="card-name">{trimmedName}</p>
      </div>
    </Col>
  );
};

export default Card;
