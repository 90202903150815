import React, { createContext, useState, useContext } from "react";

// Create a Context for the player
const PlayerContext = createContext();

// Create a Provider component
export const PlayerProvider = ({ children }) => {
  const [player, setPlayer] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  // Function to update the player
  const updatePlayer = (newPlayer) => {
    setPlayer(newPlayer);
  };

  return (
    <PlayerContext.Provider
      value={{ player, updatePlayer, accessToken, setAccessToken }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

// Custom hook for using the PlayerContext
export const usePlayer = () => {
  return useContext(PlayerContext);
};
