import React, { useState, useEffect, useContext } from "react";
import { RoomContext } from "../Context/RoomContext";
import { TimeContext } from "../Context/TimeContext";
import { useNavigate } from "react-router-dom";
import { cardSlot } from "./Data";

const Timer = ({ turn, clientId, changeTurn, roundOne, roundTwo }) => {
  // const [time, setTime] = useState();

  const navigate = useNavigate();

  const { time, setTime } = useContext(TimeContext);

  const {
    score,
    cardsList,
    lastTurn,
    singlePlayer,
    roundOneOver,
    roundTwoStart,
    roundTwoBoard,
    startRoundTwoTimer,
    setRoundTwoFinished,
    roundTwoCompletedByPlayerOne,
    roundTwoCompletedByPlayerTwo,
    setRoundTwoCardsSequence,
  } = useContext(RoomContext);

  useEffect(() => {
    if (roundTwo) {
      // if (time === 0) {
      //   lastTurn
      //     ? roundTwoCompletedByPlayerTwo(score)
      //     : changeTurn(cardsList, roundTwoBoard);
      // }
      if (!singlePlayer) {
        if (time === 0) {
          roundTwoCompletedByPlayerTwo(score);
          // lastTurn
          //   ? roundTwoCompletedByPlayerTwo(score)
          //   : roundTwoCompletedByPlayerOne(score);
        }
      } else {
        if (time === 0) {
          setRoundTwoCardsSequence("wrong");
          setRoundTwoFinished(true);
        }
      }
      // time > 0 && setTimeout(() => setTime(time - 1), 1000);
    } else {
      if (!singlePlayer) {
        if (turn === clientId) {
          if (time === 0) {
            lastTurn
              ? roundTwoCompletedByPlayerTwo(score)
              : changeTurn(cardsList, roundTwoBoard);
          }
          // time > 0 && setTimeout(() => setTime(time - 1), 1000);
        }
      } else {
        if (time === 0) {
          setTimeout(() => {
            // setRoundOneBoard([...cardSlot]);
            roundOneOver();
            roundTwoStart();
            navigate("/roundoneover", { replace: true });
          }, 1000);
        }
        // time > 0 && setTimeout(() => setTime(time - 1), 1000);
      }
    }
  }, [time, turn]);

  return <div>{time}</div>;
};

export default Timer;
