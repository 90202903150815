import React, { useState, useContext } from "react";
import { useDrop, useDrag } from "react-dnd";
import { css } from "aphrodite";
import { styles } from "./Animations";
import { Col, Button, Modal } from "react-bootstrap";
import { RoomContext } from "../Context/RoomContext";
import "./Game.css";
import RoundTwo from "./RoundTwo";

const CardSlot = ({
  id,
  name,
  url,
  index,
  backUrl,
  date,
  dateType,
  cardDrop,
  cardVanish,
  dropedCardId,
  roundTwo,
  showCardDate,
  addImageToCard,
  moveWrongCard,
  onCardHover,
  singlePlayer,
  board,
  hover,
  setHover,
  roundOne,
  roundTwoBoard,
  wrongDropsList,
  dropedCardIndex,
}) => {
  const [flip, setFlip] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const roomContext = useContext(RoomContext);
  const { clientId, turn } = roomContext;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "move",
      item: {
        id: id,
        name: name,
        url: url,
        backUrl: backUrl,
        date: date,
        dateType: dateType,
        type: "move",
        // canDrop: canDrop,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [roundTwoBoard]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ["image", "move"],

      drop: (item, monitor) => {
        if (roundOne) {
          addImageToCard(item, monitor, id);
        } else {
          item.type === "move"
            ? moveWrongCard(item, monitor, id)
            : addImageToCard(item, monitor, id);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [board]
  );

  const wrongCardId = wrongDropsList?.[0];

  const MyVerticallyCenteredModal = (props) => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="cardModal"
        style={{ backgroundColor: "transparent" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Card Details
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <center>
            {/* <h4>{name}</h4> */}
            <img
              style={{
                height: "53vh",
              }}
              src={backUrl}
              alt="card back side"
            />
            {/* <h4>
              {new Date(date).toDateString().split(" ").slice(1).join(" ")}
            </h4> */}
          </center>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  };

  if (singlePlayer) {
    if (roundOne && isOver) {
      onCardHover(id);
    } else if (roundTwo && isOver) {
      onCardHover(id);
    }
  } else {
    if (roundOne && turn === clientId && isOver) {
      onCardHover(id);
    } else if (roundTwo && isOver) {
      onCardHover(id);
    }
  }

  const flipCard = () => {
    if (roundOne && name !== "cardSlot") {
      // setFlip(!flip);
      setModalShow(!modalShow);
    }
  };

  const cardDate = () => {
    if (date) {
      if (dateType && dateType === "BC") {
        const convertedDate = new Date(date * 1)
          .toDateString()
          .split(" ")
          .slice(1, 3)
          .join(" ");
        const year = new Date(date * 1).getFullYear() * -1;
        console.log("year", year);
        return `(${convertedDate} ${year} BC)`;
      } else {
        return `(${new Date(date)
          .toDateString()
          .split(" ")
          .slice(1)
          .join(" ")})`;
      }
    }
  };

  let trimmedName = name.length > 28 ? name.substring(0, 25) + "..." : name;

  console.log("Date", name, date, dateType, new Date(date * 1).toDateString());
  // console.log(date);
  // console.log(new Date(date).toDateString().split(" ").slice(1).join(" "));

  return (
    <Col
      className="lastCardSlotPaddingRight"
      style={{ padding: "0", textAlign: "center", margin: "0 4px" }}
      // className="cardSlotWrapper"
      // style={{
      //   maxWidth: "14vmin",
      //   width: "90%",
      //   margin: "0 0.8vmin",
      //   // padding: "0px 5px",
      //   whiteSpace: "pre-line",
      // }}
    >
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div ref={drop} onClick={flipCard}>
        <img
          // className={
          //   (cardVanish && dropedCardId === id
          //     ? `${css(styles.vanishOut)} cardSlot`
          //     : "cardSlot",
          //   flip ? "cardSlotBack" : "")
          // }
          // className="cardSlot"
          className={flip ? "cardSlotBack cardSlot" : "cardSlot"}
          src={flip ? backUrl : url}
          ref={wrongCardId && id === wrongCardId ? drag : null}
          alt={name}
          title={name}
          style={{
            background: "transparent",
            height: "15vh",
            border:
              wrongCardId && id === wrongCardId ? "2px solid red" : "none",
          }}
        />
        <div>
          {roundTwo
            ? showCardDate && date && <p className="card-date">{cardDate()}</p>
            : date && <p className="card-date">{cardDate()}</p>}
          {name && name !== "cardSlot" && name !== "emptyCard" && (
            <p className="card-name">{trimmedName}</p>
          )}
        </div>
      </div>
    </Col>
  );
};

export default CardSlot;
