import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { isMobile } from "react-device-detect";
import { Row, Col, Container } from "react-bootstrap";

const ForgotPassword = () => {
  return (
    <div>
      {isMobile ? (
        <Container
          fluid
          style={{
            height: "100vh",
            background: "url(/images/background.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h1 className="loginTitle">
            <img src="/images/Time-Quest.png" />
          </h1>
          <Row>
            <Col className="column">
              <ForgotPasswordForm />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          fluid
          style={{
            height: "100vh",
            background: "url(/images/login-background.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h1 className="loginTitle">
            <img src="/images/Time-Quest.png" />
          </h1>
          <Row style={{ height: "90vh" }}>
            <Col className="column" />

            <Col className="column">
              <ForgotPasswordForm />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default ForgotPassword;
