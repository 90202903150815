import React, { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

import Card from "./Card";
import RefCards from "./RefCards";

import { cardSlot, emptyCard } from "./Data";
import { RoomContext } from "../Context/RoomContext";
import { Row } from "react-bootstrap";
import "./Game.css";

const RoundOne = ({ score, setScore, roundOne, roundTwo, setRound }) => {
  let roomContext = useContext(RoomContext);
  const {
    // setRoundOneDeckCardsLength,
    // setRoundOneBoardIndex,
    activePlayerBoard,
    setActivePlayerBoard,
    setInactivePlayerBoard,
    setInactivePlayerCards,
    singlePlayer,
    cardsList,
    setCardsList,
    setCLientCards,
    shareData,
    onRoundOneDrop,
    changeTurn,
    answer,
    setAnswer,
    newCard,
    roundTwoStart,
    checkDragging,
    opponentScore,
    opponentJoined,
    roundOneOver,
    roundOneFinished,
  } = roomContext;

  let navigate = useNavigate();

  const [roundOneCards, setRoundOneCards] = useState([...cardsList]);
  const [roundOneBoard, setRoundOneBoard] = useState([...activePlayerBoard]);

  const [insertCard, setInsertCard] = useState(false);
  const [hover, setHover] = useState(false);
  const [hoverIndex, setHoverIndex] = useState();
  // const [rightDropedCard, setRightDropedCard] = useState();

  const [onHoverIndex, setOnHoverIndex] = useState();
  // const [cardFlip, setCardFlip] = useState();

  // roundOneCards && setRoundOneDeckCardsLength(roundOneCards.length);

  console.log(activePlayerBoard, cardsList);
  console.log(onHoverIndex);

  useEffect(() => {
    setRound("roundOne");
  }, []);

  useEffect(() => {
    setRoundOneBoard([...activePlayerBoard]);
  }, [activePlayerBoard]);

  const notify = (cardDrop, cardDate) => {
    console.log(
      "card-date",
      new Date(cardDate).toDateString().split(" ").slice(1).join(" ")
    );
    switch (cardDrop) {
      case "rightDrop":
        toast.success("Right Drop");
        break;
      case "left":
        toast.warning("Opponent Left");
        break;
      default:
        toast.error(
          <>
            <h6>Wrong Drop</h6>
            <h6>
              Card Date:{" "}
              {new Date(cardDate).toDateString().split(" ").slice(1).join(" ")}
            </h6>
          </>
        );
        break;
    }
  };

  console.log("opponentJoined", opponentJoined);

  // useEffect(() => {
  //   if (opponentLeft === true) {
  //     notify("left");
  //     navigate("/choose-opponent", { replace: true });
  //   }
  // }, [opponentLeft]);

  const onHover = (dragedCard, monitor, cardHoverIndex) => {
    setOnHoverIndex(cardHoverIndex);
  };

  useEffect(() => {
    // let index = roundOneBoard.findIndex((item) => item.id === emptyCard.id);

    let checkEmptyCard = roundOneBoard.findIndex(
      (item) => item?.id === emptyCard?.id
    );

    if (!hover) {
      setRoundOneBoard(
        roundOneBoard.filter((card) => card?.id !== emptyCard.id)
      );
    } else {
      let left = onHoverIndex - 1;
      let right = onHoverIndex + 1;
      let hoverName;
      let leftCardName;
      let rightCardName;

      if (left >= 0) {
        leftCardName = roundOneBoard[left].name;
      }
      if (right < roundOneBoard.length) {
        rightCardName = roundOneBoard[right].name;
      }
      if (roundOneBoard[onHoverIndex]) {
        hoverName = roundOneBoard[onHoverIndex].name;
      }

      if (hoverName === "cardSlot") {
        console.log("hover on card slot");
        // roundOneBoard.splice(checkEmptyCard, 1);
        setRoundOneBoard(
          roundOneBoard.filter((card) => card.id !== emptyCard.id)
        );
      }

      if (checkEmptyCard !== -1) {
        console.log("emptycard", checkEmptyCard);
        if (onHoverIndex > checkEmptyCard) {
          roundOneBoard.splice(checkEmptyCard, 1);
          roundOneBoard.splice(left, 0, { ...emptyCard });
        } else if (onHoverIndex < checkEmptyCard) {
          roundOneBoard.splice(checkEmptyCard, 1);
          roundOneBoard.splice(right, 0, { ...emptyCard });
        } else {
          return;
        }
      } else {
        console.log("false");
      }

      if (checkEmptyCard === -1) {
        if (hoverName !== "emptyCard" && hoverName !== "cardlot") {
          if (leftCardName && rightCardName && hoverName) {
            if (leftCardName !== "cardSlot" && hoverName !== "cardSlot") {
              roundOneBoard.splice(onHoverIndex, 0, { ...emptyCard });
            }
          } else if (hoverName) {
            if (rightCardName && hoverName !== "cardlot") {
              console.log("hovering on most left card");
              roundOneBoard.splice(onHoverIndex, 0, { ...emptyCard });
            } else if (leftCardName && hoverName !== "cardlot") {
              console.log(hoverName);
              console.log("hovering on most right card");
              roundOneBoard.splice(right, 0, { ...emptyCard });
              // roundOneBoard.push({ ...emptyCard });
            }
          }
        }
      }
    }
  }, [onHoverIndex, hover]);

  const onCardHover = (id) => {
    const index = roundOneBoard.findIndex((card) => card?.id === id);

    setHoverIndex(index);
  };

  console.log(hoverIndex);

  const addImageToBoard = (dropedCard) => {
    let dropedCardIndex = roundOneBoard.findIndex(
      (item) => item.id === emptyCard.id
    );

    if (
      roundOneBoard[dropedCardIndex] &&
      roundOneBoard[dropedCardIndex].name !== "cardSlot"
    ) {
      roundOneBoard[dropedCardIndex] = { ...dropedCard };
      setRoundOneBoard([...roundOneBoard]);

      let checkDates = roundOneBoard.map((card, index) => {
        if (index > dropedCardIndex) {
          return roundOneBoard[dropedCardIndex].date > card.date;
        } else if (index < dropedCardIndex) {
          return roundOneBoard[dropedCardIndex].date < card.date;
        }
      });

      let checkingDates = checkDates.find((item) => item === true);

      let left = dropedCardIndex - 1;
      let right = dropedCardIndex + 1;
      let hoverDate;
      let leftCardDate;
      let rightCardDate;

      if (left > 0) {
        leftCardDate = roundOneBoard[left].date;
      }
      if (right < roundOneBoard.length) {
        rightCardDate = roundOneBoard[right].date;
      }
      if (roundOneBoard[onHoverIndex]) {
        hoverDate = roundOneBoard[onHoverIndex].date;
      }

      // setRightDropedCard(dropedCard);

      if (dropedCard.date < rightCardDate && dropedCard.date > leftCardDate) {
        const updatedScore = score + 10;

        setAnswer("correct");
        notify("rightDrop", dropedCard.date);
        setScore(updatedScore);

        // notification("rightDrop");
        // setShowModal(true);
        // setModalInfo({
        //   title: "Right Card",
        //   cardDate: dropedCard.date,
        // });

        let updatedCards = roundOneCards.filter(
          (card) => card.id !== dropedCard.id
        );
        setRoundOneCards([...updatedCards]);
        setCardsList([...updatedCards]);
        onRoundOneDrop(
          "correct",
          updatedCards,
          roundOneBoard,
          dropedCard
          // updatedScore,
          // opponentScore
        );

        if (updatedCards.length === 0) {
          setTimeout(() => {
            setRoundOneBoard([...cardSlot]);
            roundOneOver();
            roundTwoStart();
            navigate("/roundoneover", { replace: true });
          }, 1000);
        }
        console.log("updatedCards", updatedCards);
        setTimeout(() => {
          if (updatedCards.length !== 0) {
            console.log("changing turn");
            // shareData(score);
            !singlePlayer && changeTurn(updatedCards, updatedScore);
          } else {
            // roundTwoStart();
          }
        }, 500);
      } else if (!rightCardDate && dropedCard.date > leftCardDate) {
        const updatedScore = score + 10;

        setAnswer("correct");
        notify("rightDrop", dropedCard.date);
        setScore(updatedScore);

        let updatedCards = roundOneCards.filter(
          (card) => card.id !== dropedCard.id
        );
        setRoundOneCards([...updatedCards]);
        setCardsList([...updatedCards]);
        onRoundOneDrop(
          "correct",
          updatedCards,
          roundOneBoard,
          dropedCard
          // updatedScore,
          // opponentScore
        );

        if (updatedCards.length === 0) {
          setTimeout(() => {
            setRoundOneBoard([...cardSlot]);
            roundOneOver();
            roundTwoStart();
            navigate("/roundoneover", { replace: true });
          }, 1000);
        }
        console.log("updatedCards", updatedCards);
        setTimeout(() => {
          if (updatedCards.length !== 0) {
            // shareData(score);
            !singlePlayer && changeTurn(updatedCards, updatedScore);
          } else {
            // roundTwoStart();
          }
        }, 500);
      } else if (!leftCardDate && dropedCard.date < rightCardDate) {
        const updatedScore = score + 10;

        setAnswer("correct");
        notify("rightDrop", dropedCard.date);
        setScore(updatedScore);

        let updatedCards = roundOneCards.filter(
          (card) => card.id !== dropedCard.id
        );
        setRoundOneCards([...updatedCards]);
        setCardsList([...updatedCards]);
        onRoundOneDrop(
          "correct",
          updatedCards,
          roundOneBoard,
          dropedCard
          // updatedScore,
          // opponentScore
        );

        if (updatedCards.length === 0) {
          setTimeout(() => {
            setRoundOneBoard([...cardSlot]);
            roundOneOver();
            roundTwoStart();
            navigate("/roundoneover", { replace: true });
          }, 1000);
        }
        console.log("updatedCards", updatedCards);
        setTimeout(() => {
          if (updatedCards.length !== 0) {
            // shareData(score);
            !singlePlayer && changeTurn(updatedCards, updatedScore);
          } else {
            // roundTwoStart();
          }
        }, 500);
      } else {
        setAnswer("wrong");
        notify("wrongDrop", dropedCard.date);

        let updatedCards = roundOneCards.filter(
          (card) => card.id !== dropedCard.id
        );
        setRoundOneCards([...updatedCards]);

        setTimeout(() => {
          updatedCards.push(newCard);
          setRoundOneCards([...updatedCards]);
          roundOneBoard.splice(dropedCardIndex, 1);
          // let updatedBoard = roundOneCards.filter(
          //   (card) => card.id !== dropedCard.id
          // );
          setRoundOneBoard([...roundOneBoard]);
          setCardsList([...updatedCards]);
          onRoundOneDrop(
            "wrong",
            updatedCards,
            roundOneBoard,
            dropedCard,
            score
          );
          // setCardVanish(true);
        }, 1000);

        // setTimeout(() => {
        //   roundOneBoard.splice(dropedCardIndex, 1);
        //   setRoundOneBoard(roundOneBoard);
        //   // setCardVanish(false);
        //   // setShowModal(true);
        // }, 1000);
        console.log("updatedCards", updatedCards);
        setTimeout(() => {
          if (updatedCards.length !== 0) {
            // shareData(score);
            !singlePlayer && changeTurn(updatedCards, score);
          } else {
            // roundTwoStart();
          }
        }, 1500);
      }
    }
  };

  const addImageToCard = (dropedCard, monitor, id) => {
    console.log("drop slot id", roundOneBoard[onHoverIndex]);
    console.log("dropedCard", dropedCard);
    const dropedCardSlot = roundOneBoard[onHoverIndex];
    console.log(dropedCardSlot);
    let dropedCardIndex = roundOneBoard.findIndex((card) => card.id === id);

    if (roundOneBoard[dropedCardIndex].name === "cardSlot") {
      roundOneBoard[dropedCardIndex] = { ...dropedCard };

      let checkDates = roundOneBoard.map((card, index) => {
        if (index > dropedCardIndex) {
          return roundOneBoard[dropedCardIndex].date > card.date;
        } else if (index < dropedCardIndex) {
          return roundOneBoard[dropedCardIndex].date < card.date;
        }
      });

      // setRightDropedCard(dropedCard);

      let checkingDates = checkDates.find((item) => item === true);

      if (checkingDates !== true) {
        const updatedScore = score + 10;

        setAnswer("correct");
        notify("rightDrop", dropedCard.date);
        setScore(updatedScore);
        // notification("rightDrop");
        // setShowModal(true);
        // setModalInfo({
        //   title: "Right Card",
        //   cardDate: dropedCard.date,
        // });
        let updatedCards = roundOneCards.filter(
          (card) => card.id !== dropedCard.id
        );

        if (roundOneBoard.length - 1 === dropedCardIndex) {
          console.log("add-card" + " slot on right");
          roundOneBoard.push(dropedCardSlot);
        } else if (dropedCardIndex === 0) {
          console.log("add-card" + " slot on left");
          roundOneBoard.unshift(dropedCardSlot);
        }

        setRoundOneCards([...updatedCards]);
        setCardsList([...updatedCards]);
        onRoundOneDrop(
          "correct",
          updatedCards,
          roundOneBoard,
          dropedCard
          // updatedScore,
          // opponentScore
        );

        if (updatedCards.length === 0) {
          setTimeout(() => {
            setRoundOneBoard([...cardSlot]);
            roundOneOver();
            roundTwoStart();
            navigate("/roundoneover", { replace: true });
          }, 1000);
        }
        console.log("updatedCards", updatedCards);
        setTimeout(() => {
          if (updatedCards.length !== 0) {
            // shareData(score);
            !singlePlayer && changeTurn(updatedCards, updatedScore);
          } else {
            // roundTwoStart();
          }
        }, 500);
      } else {
        console.log("wrong");
        setAnswer("wrong");
        notify("wrongDrop", dropedCard.date);
        // notification("wrongDrop");
        // setCardVanish(true);
        // setModalInfo({
        //   title: "Wrong Card",
        //   cardDate: dropedCard.date,
        // });
        let updatedCards = roundOneCards.filter(
          (card) => card.id !== dropedCard.id
        );

        setRoundOneCards([...updatedCards]);
        setTimeout(() => {
          updatedCards.push(newCard);
          setRoundOneCards([...updatedCards]);
          console.log();
          roundOneBoard[dropedCardIndex] = dropedCardSlot;
          setRoundOneBoard(roundOneBoard);
          // setCardVanish(true);
          setCardsList([...updatedCards]);
          onRoundOneDrop(
            "wrong",
            updatedCards,
            roundOneBoard,
            dropedCard,
            score
          );
        }, 1000);

        // setTimeout(() => {
        //   roundOneBoard[dropedCardIndex] = cardSlot[dropedCardIndex];
        //   setRoundOneBoard(roundOneBoard);
        //   // setCardVanish(false);
        //   // setShowModal(true);
        // }, 1000);
        console.log("updatedCards", updatedCards);
        setTimeout(() => {
          if (updatedCards.length !== 0) {
            // shareData(score);
            !singlePlayer && changeTurn(updatedCards, score);
          } else {
            // roundTwoStart();
          }
        }, 1500);
      }
    }
  };

  console.log("RoundOneCards", roundOneCards, roundOneBoard);

  // useEffect(() => {
  // if (roundOneCards && roundOneBoard && rightDropedCard) {
  //   onRoundOneDrop(answer, roundOneCards, roundOneBoard, rightDropedCard);
  // }
  // setActivePlayerBoard([...roundOneBoard]);
  // setCardsList(roundOneCards);
  // setCLientCards([...roundOneCards]);
  // }, [answer, roundOneCards, roundOneBoard, hoverIndex, rightDropedCard]);

  return (
    <div>
      <Row>
        <h1
          style={{ marginBottom: "3vmin", marginTop: "-15px" }}
          className="round"
        >
          Round-1
        </h1>
      </Row>

      <Row>
        <RefCards
          roundOneBoard={roundOneBoard}
          onHover={onHover}
          onCardHover={onCardHover}
          hover={hover}
          hoverIndex={hoverIndex}
          setHover={setHover}
          addImageToBoard={addImageToBoard}
          addImageToCard={addImageToCard}
          roundOne={roundOne}
          roundTwo={roundTwo}
          singlePlayer={singlePlayer}
        />
      </Row>

      <div style={{ height: "25vmin" }}>
        <h1
          className="title"
          style={{ marginTop: "4vmin", marginBottom: "2vmin" }}
        >
          Drop Card Right or Left
        </h1>

        <Row>
          <div
            className="card-slot"
            style={{
              marginBottom: "5px",
              padding: "0 20px",
              // border: "2px solid red",
            }}
          >
            {roundOneCards &&
              roundOneCards.map((picture, index) => {
                return (
                  <Card
                    key={picture?.id}
                    id={picture?.id}
                    url={picture?.url}
                    backUrl={picture?.back_url}
                    name={picture?.name}
                    date={picture?.date}
                    dateType={picture?.dateType}
                    roundOneCards={roundOneCards}
                    insertCard={insertCard}
                    index={index}
                  />
                );
              })}
          </div>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RoundOne;
