import { StyleSheet } from "aphrodite";
import {
  swashIn,
  vanishOut,
  swap,
  slideRightReturn,
  slideLeft,
  slideRight,
} from "react-magic";

export const styles = StyleSheet.create({
  swashIn: {
    animationName: swashIn,
    animationDuration: "1s",
  },
  vanishOut: {
    animationName: vanishOut,
    animationDuration: "2s",
  },
  swap: {
    animationName: swap,
    animationDuration: "1s",
  },
  slideRightReturn: {
    animationName: slideRightReturn,
    animationDuration: "1s",
  },
  slideLeft: {
    animationName: slideLeft,
    animationDuration: "0.5s",
  },
  slideRight: {
    animationName: slideRight,
    animationDuration: "0.5s",
  },
});
