export const playerWin = async (score, selectedCategory) => {
  const {
    email,
    cityId,
    stateId,
    schoolId,
    classId,
    gameAccessToken,
    accessToken,
  } = JSON.parse(localStorage.getItem('userData'));

  console.log(score);
  console.log(selectedCategory);

  let axios = require('axios');
  let data = {
    // city_id: JSON.stringify(cityId),
    // state_id: JSON.stringify(stateId),
    // school_id: JSON.stringify(schoolId),
    class_id: JSON.stringify(classId),
    category_id: JSON.stringify(selectedCategory.id),
    category: selectedCategory.category,
    std_email: email,
    std_scores: JSON.stringify(score),
    // country_id: JSON.stringify(countryId),
  };

  let config = {
    method: 'post',
    url: `${process.env.REACT_APP_BACKEND_URL}/won`,
    headers: {
      token: gameAccessToken,
    },
    data: data,
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res);
  } catch (error) {
    err = error;
    console.log(error);
  }

  // axios(config)
  //   .then((response) => {
  //     console.log(JSON.stringify(response.data));
  //     res = response;
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     err = error;
  //   });

  return res ? res : err;
};
