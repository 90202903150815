import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Row, col} from "react-bootstrap";
import {fetchLeaderboardData} from "../api/leaderboard";
import "./Game.css";

const LeaderBoard = () => {
  const [loading, setLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState();

  const {schoolId, cityId, stateId} = JSON.parse(
    localStorage.getItem("userData")
  );

  // const navigate = useNavigate();

  useEffect(async () => {
    fetchLeaderboardData("school", schoolId)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            console.log(res);
            let schoolData = [];
            console.log(res.data);
            res.data.map(item => {
              console.log(item.studentData.studentDetails);
              schoolData = [
                ...schoolData,
                {...item.studentData.studentDetails},
              ];
            });
            schoolData.sort((a, b) => b.studentScores - a.studentScores);
            console.log(schoolData);
            setLeaderboardData(schoolData);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        }
      })
      .catch(() => {});
  }, []);

  useEffect(async () => {}, []);

  const handleSchool = () => {
    setLoading(true);
    fetchLeaderboardData("school", schoolId)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            console.log(res);
            let schoolData = [];
            console.log(res.data);
            res.data.map(item => {
              console.log(item.studentData.studentDetails);
              schoolData = [
                ...schoolData,
                {...item.studentData.studentDetails},
              ];
            });
            schoolData.sort((a, b) => b.studentScores - a.studentScores);
            console.log(schoolData);
            setLeaderboardData(schoolData);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        }
      })
      .catch(() => {});
  };

  const handleCity = () => {
    setLoading(true);
    fetchLeaderboardData("city", cityId)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            console.log(res);
            let cityData = [];
            console.log(res.data);
            res.data.map(item => {
              console.log(item);
              cityData = [...cityData, ...item.studentData.studentDetails];
            });
            console.log(cityData);
            cityData.sort((a, b) => b.studentScores - a.studentScores);
            console.log(cityData);
            setLeaderboardData(cityData);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        }
      })
      .catch(() => {});
  };

  const handleState = () => {
    setLoading(true);
    fetchLeaderboardData("state", stateId)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            console.log(res);
            // FILTER
            let stateData = [];
            console.log(res.data);
            res.data.map(item => {
              if (item.studentData.studentDetails.length > 0) {
                stateData = [...stateData, ...item.studentData.studentDetails];
              }
            });
            stateData.sort((a, b) => b.studentScores - a.studentScores);
            // FILTER END

            console.log("stateData", stateData);

            setLeaderboardData(stateData);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        }
      })
      .catch(() => {});
  };

  const handleCountry = () => {
    setLoading(true);
    fetchLeaderboardData("country", "1")
      .then(res => {
        if (res) {
          console.log(res.data);
          if (res.status === 200) {
            let countryData = [];
            console.log(res.data);
            res.data.map(item => {
              if (item.studentData.studentDetails.length > 0) {
                countryData = [
                  ...countryData,
                  ...item.studentData.studentDetails,
                ];
              }
            });
            countryData.sort((a, b) => b.studentScores - a.studentScores);
            // FILTER END

            console.log(countryData);
            setLeaderboardData(countryData);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="leaderboardContainer">
        <div className="leaderboardBox">
          <div className="leadboardAlign">
            <div className="leadboardHeader">
              <h1>LEADERBOARD</h1>
            </div>
            {/* <div className="leaderboardCategories">
              <div className="leaderboardCategoryItem" onClick={handleSchool}>
                <p>School</p>
              </div>
              <div className="leaderboardCategoryItem" onClick={handleCity}>
                <p>City</p>
              </div>
              <div className="leaderboardCategoryItem" onClick={handleState}>
                <p>State</p>
              </div>
              <div className="leaderboardCategoryItem" onClick={handleCountry}>
                <p>Country</p>
              </div>
            </div> */}

            {loading ? (
              <div
                className="d-flex justify-content-center"
                style={{height: "40vh"}}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : leaderboardData.length > 0 ? (
              <div className="d-flex justify-content-center">
                <div className="leaderboardScores">
                  {leaderboardData &&
                    leaderboardData.map((item, index) => {
                      // const { studentData } = item;
                      // const { studentDetails } = studentData;
                      // console.log(studentDetails);
                      // console.log(studentDetails.dataValues);
                      return (
                        <div className="leaderboardScoreItem" key={index}>
                          <div className="leaderboardScoreCount">
                            {index + 1}
                          </div>
                          <div
                            className="leaderboardScoreContent"
                            style={{marginLeft: "10px"}}
                          >
                            {item?.dataValues?.std_name || item?.username}
                          </div>
                          <div className="leaderboardScoreContent">
                            {item?.studentScores || item?.coins}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="leaderboardScores">
                  <h2>No Data Found</h2>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <button
            className="leaderboadr-btn"
            onClick={() => (window.location.href = "/")}
          >
            Menu
          </button>
        </div>
      </div>
    </>
  );
};

export default LeaderBoard;
