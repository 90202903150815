import React, { useEffect, useContext } from "react";
import { RoomContext } from "../Context/RoomContext";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Game.css";

const PlayerLose = () => {
  const {
    setScore,
    rematch,
    opponentId,
    opponentLeft,
    playerRematch,
    confirmRematch,
    cancelRematch,
    startRematch,
    restartGame,
    startRoundOne,
    setOpponentScore,
    finishGame,
  } = useContext(RoomContext);

  let navigate = useNavigate();

  const notify = (response) => {
    if (response === "left") {
      toast.warning("Opponent Left");
    } else if (response === "waiting") {
      toast.loading("Please wait...", {
        toastId: 2,
      });
    } else if (response === "rematch") {
      toast.warning(
        <center>
          <h4>Oppent Want To Rematch</h4>

          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "20px", marginRight: "5px" }}
            onClick={handleCancelRematch}
          >
            cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "20px", marginLeft: "5px" }}
            onClick={handleConfirmRematch}
          >
            confirm
          </button>
        </center>,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else if (response === "confirmRematch") {
      toast.success("Starting Game...");
    } else if (response === "cantRematch") {
      toast.warning(
        <div>
          <h>Opponet left cant rematch</h>
        </div>
      );
    }
  };

  useEffect(() => {
    window.onbeforeunload = undefined;
  }, []);

  useEffect(() => {
    if (opponentLeft === true) {
      notify("left");
      setTimeout(() => {
        setScore(0);
        setOpponentScore(0);
      }, 1000);
    }
  }, [opponentLeft]);

  useEffect(() => {
    if (rematch === true) {
      notify("rematch");
    }
  }, [rematch]);

  useEffect(() => {
    if (startRematch === true) {
      toast.update(2, {
        render: "Rematch Accepted Starting Game...",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } else if (!opponentId) {
      console.log("rematch canceled");
      toast.update(2, {
        render: "Opponent declined rematch request",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }, [startRematch]);

  useEffect(() => {
    if (startRoundOne) {
      navigate("/game", { replace: true });
    }
  }, [startRoundOne]);

  const handleLeaderboard = () => {
    // navigate("/leaderboard", { replace: true });
    window.location.href = "/leaderboard";
  };

  const handleMainMenu = () => {
    // navigate("/", { replace: true });
    window.location.href = "/";
  };

  const handleRematch = () => {
    console.log("rematch called");
    if (opponentId) {
      console.log("wating for response");
      notify("waiting");
      playerRematch();
    } else {
      console.log("cant rematch");
      notify("cantRematch");
    }
  };

  const handleConfirmRematch = () => {
    confirmRematch();
    notify("confirmRematch");
  };

  const handleCancelRematch = () => {
    cancelRematch();
  };

  return (
    <div className="player-win">
      <Container>
        <Row>
          <Col>
            <div className="player-win-container">
              <img
                style={{ width: "55%", marginBottom: "0px" }}
                src="/images/Time-Quest.png"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12} style={{ zIndex: "0" }}>
            <div className="player-win-container">
              <img
                className="player-win-box"
                src="/images/player-lose-box-fix.jpg"
              />

              <div className="player-win-btn">
                <button
                  style={{
                    width: "150%",
                    border: "none",
                    background: "none",
                  }}
                  onClick={handleMainMenu}
                >
                  <img
                    className="player-win-btn-menu"
                    // style={{
                    //   width: "100%",
                    //   marginBottom: "3vmin",
                    // }}
                    src="/images/main-menu-btn.png"
                  />
                </button>
                <button
                  style={{
                    width: "150%",
                    border: "none",
                    background: "none",
                  }}
                  onClick={handleRematch}
                >
                  <img
                    className="player-win-btn-rematch"
                    // style={{ width: "100%", marginBottom: "3vmin" }}
                    src="/images/rematch-btn.png"
                  />
                </button>
                <button
                  style={{ width: "150%", border: "none", background: "none" }}
                  onClick={handleLeaderboard}
                >
                  <img
                    className="player-win-btn-leaderboard"
                    // style={{ width: "100%" }}
                    src="/images/leaderboard-btn.png"
                  />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default PlayerLose;
