import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CardSlot from "./CardSlot";
import { useDrop } from "react-dnd";
import "./Game.css";

const RefCards = ({
  roundOneBoard,
  roundTwoBoard,
  roundTwoCards,
  addImageToBoard,
  addImageToCard,
  dropedCardIndex,
  wrongDropsList,
  moveWrongCard,
  showCardDate,
  onHover,
  onCardHover,
  hover,
  hoverIndex,
  setHover,
  roundOne,
  roundTwo,
  singlePlayer,
}) => {
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: roundOne ? "image" : "move",
      hover: (item, monitor) => {
        onHover(item, monitor, hoverIndex);
      },
      drop: (item, monitor) => {
        addImageToBoard(item, monitor);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [roundOneBoard, roundTwoBoard, hoverIndex]
  );

  useEffect(() => {
    setHover(isOver);
  }, [isOver]);

  console.log("roundoneboard", roundOneBoard);

  return (
    <div ref={drop} className="card-slot-sub-container">
      <Row
        style={{
          display: "flex",
          flexWrap: "nowrap",
          marginLeft: "5px",
          marginRight: "5px",
          //   width: "100%",
          //   height: "25vh",
          //   paddingBottom: "10px",
          //   display: "flex",
          //   textAlign: "center",
          // maxWidth: "500px",
          // overflowX: "auto",
          // overflowY: "hidden",
          // whiteSpace: "nowrap",
        }}
      >
        {roundOneBoard &&
          roundOneBoard.map((picture) => {
            if (picture) {
              return (
                <CardSlot
                  key={picture.id}
                  id={picture.id}
                  name={picture.name}
                  url={picture.url}
                  backUrl={picture.backUrl ? picture.backUrl : picture.back_url}
                  date={picture.date}
                  dateType={picture?.dateType}
                  cardDrop={picture.canDrop}
                  board={roundOneBoard}
                  addImageToCard={addImageToCard}
                  onCardHover={onCardHover}
                  hover={hover}
                  setHover={setHover}
                  roundOne={roundOne}
                  roundTwo={roundTwo}
                  singlePlayer={singlePlayer}
                />
              );
            }
          })}

        {roundTwoBoard &&
          roundTwoBoard.map((picture, index) => {
            if (picture) {
              return (
                <CardSlot
                  key={picture.id}
                  id={picture.id}
                  index={index}
                  roundTwo={roundTwo}
                  name={picture.name}
                  url={picture.url}
                  date={picture.date}
                  dateType={picture?.dateType}
                  cardDrop={picture.canDrop}
                  board={roundTwoBoard}
                  addImageToCard={addImageToCard}
                  moveWrongCard={moveWrongCard}
                  showCardDate={showCardDate}
                  onCardHover={onCardHover}
                  wrongDropsList={wrongDropsList}
                  roundTwoBoard={roundTwoBoard}
                  roundTwoCards={roundTwoCards}
                  dropedCardIndex={dropedCardIndex}
                  singlePlayer={singlePlayer}
                />
              );
            }
          })}
      </Row>
    </div>
  );
};

export default RefCards;
