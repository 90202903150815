import React, { createContext, useContext, useState, useEffect } from "react";
import { RoomContext } from "./RoomContext";

export const TimeContext = createContext();

export const TimeProvider = ({ children }) => {
  const [time, setTime] = useState(null);

  const {
    turn,
    clientId,
    roundOne,
    roundTwo,
    singlePlayer,
    startRoundTwoTimer,
  } = useContext(RoomContext);

  // console.log(turn, clientId);

  useEffect(() => {
    if (roundOne && singlePlayer) {
      setTime(180);
    } else if (roundOne && !singlePlayer) {
      setTime(90);
    } else if (roundTwo) {
      setTime(180);
    } else if (startRoundTwoTimer) {
      setTime(180);
    } else {
      setTime("");
    }
  }, [roundOne, roundTwo, startRoundTwoTimer]);

  // useEffect(() => {
  //   if (!singlePlayer) {
  //     if (roundOne && turn === clientId) {
  //       time > 0 && setTimeout(() => setTime(time - 1), 1000);
  //     } else if (roundOne && turn !== clientId && time >= 0) {
  //       setTime(90);
  //     } else if (startRoundTwoTimer) {
  //       time > 0 && setTimeout(() => setTime(time - 1), 1000);
  //     }
  //   } else if ((roundOne || roundTwo) && singlePlayer) {
  //     time > 0 && setTimeout(() => setTime(time - 1), 1000);
  //   }
  // }, [time, turn, roundOne, roundTwo, startRoundTwoTimer, singlePlayer]);

  useEffect(() => {
    if (time < 0) return;
    if (!singlePlayer) {
      if (roundOne && turn === clientId) {
        const timerId = setTimeout(
          () => setTime((prevTime) => prevTime - 1),
          1000
        );
        return () => clearTimeout(timerId);
      } else if (roundOne && turn !== clientId) {
        setTime(90);
      } else if (startRoundTwoTimer) {
        const timerId = setTimeout(
          () => setTime((prevTime) => prevTime - 1),
          1000
        );
        return () => clearTimeout(timerId);
      }
    } else if ((roundOne || roundTwo) && singlePlayer) {
      const timerId = setTimeout(
        () => setTime((prevTime) => prevTime - 1),
        1000
      );
      return () => clearTimeout(timerId);
    }
  }, [time, turn, roundOne, roundTwo, startRoundTwoTimer, singlePlayer]);

  //   useEffect(() => {
  //     if (startRoundTwoTimer) {
  //       time > 0 && setTimeout(() => setTime(time - 1), 1000);
  //     }
  //   }, [time, startRoundTwoTimer]);

  // console.log(time, roundOne, roundTwo);

  return (
    <TimeContext.Provider value={{ time, setTime }}>
      {children}
    </TimeContext.Provider>
  );
};
