import React from "react";
import Game from "./Game";
import { DndProvider } from "react-dnd";
// import { isMobile } from 'react-device-detect';
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import { Row, Col, Container } from "react-bootstrap";
import "../index.css";

const GameParent = () => {
  return (
    <div>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Row style={{ marginRight: "0" }}>
          <Col style={{ margin: "0" }} />
          <Col lg={5} md={12} sm={12} style={{ paddingRight: "0" }}>
            <Container
              className={
                isMobile
                  ? "mobContainer alignCenter p-0"
                  : "webcontainer alignCenter"
              }
            >
              <Game />
            </Container>
          </Col>
          <Col style={{ margin: "0" }} />
        </Row>
      </DndProvider>
    </div>
  );
};

export default GameParent;
