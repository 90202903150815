import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ForgotPassword } from "../api/forgotPassword";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState();

  const notify = (res) => {
    if (res === "success") {
      toast.success("Please check your inbox");
    } else if (res === "notFound") {
      toast.error("No user found with this email");
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    if (email) {
      console.log(email);
      ForgotPassword(email)
        .then((res) => {
          console.log(res);
          if (res) {
            if (res.status === 200) {
              notify("success");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 404) {
            notify("noFound");
          }
        });
    } else {
      console.log("no data");
    }

    e.preventDefault();
  };

  return (
    <div>
      <form className="loginForm" autoComplete="off">
        <div style={{ width: "62%", margin: "15px auto" }}>
          <img style={{ width: "100%" }} src="/images/forgot-pass.png" />
        </div>

        {/* <input type="text" placeholder='Username' />
                <br /> */}

        <input
          type="email"
          placeholder="Email Address"
          autoComplete="new-password"
          onChange={handleEmail}
        />

        <button type="button" onClick={handleSubmit}>
          <img style={{ width: "100%" }} src="/images/submit-btn.png" />
        </button>

        <button type="submit">
          <Link to="/">
            <img style={{ width: "100%" }} src="/images/back-btn.png" />
          </Link>
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ForgotPasswordForm;
