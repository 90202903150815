import React, { useState, useEffect } from "react";

const ChooseOpponentShuffle = ({
  opponentJoined,
  opponentAvatar,
  startRoundOne,
}) => {
  const [counter, setCounter] = useState(0);

  let data = [
    { img: "/images/card1.png" },
    { img: "/images/card2.png" },
    { img: "/images/card3.png" },
    { img: "/images/card4.png" },
  ];

  useEffect(() => {
    setTimeout(() => {
      setCounter((counter + 1) % data.length);
    }, 50);
  }, [counter]);

  return (
    <img
      className="player-image-choose-opponent"
      src={opponentJoined && startRoundOne ? opponentAvatar : data[counter].img}
      alt="player-avatar"
    />
  );
};

export default ChooseOpponentShuffle;
