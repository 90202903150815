import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { RoomProvider } from './Context/RoomContext';

import { TimeProvider } from './Context/TimeContext';
import { UserContext, UserProvider } from './Context/UserContext';
import { isMobile } from 'react-device-detect';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import GameParent from './components/GameParent';
import Catagories from './components/Catagories';
import Waiting from './components/Waiting';
import ChooseOpponent from './components/ChooseOpponent';
import PlayerWin from './components/PlayerWin';
import PlayerLose from './components/PlayerLose';
import SelectCategory from './components/SelectCategory';
import RoundOneFinished from './components/RoundOneFinished';
import RoundTwoFinished from './components/RoundTwoFinished';
import LeaderBoard from './components/leaderboard';
import Profile from './components/Profile';
import Menu from './components/Menu';
import NewPassword from './components/NewPassword';
import LoginRoute from './loginRoute';
import ProtectedRoutes from './protectedRoutes';
// import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { updateTimePlayed } from './api/profile';
import { usePlayer } from './Context/PlayerContext';
// import InactivePlayer from "./components/InactivePlayer";

function App() {
  const [error, setError] = useState(false);

  const { player, updatePlayer, setAccessToken, accessToken } = usePlayer();
  let userContext = useContext(UserContext);
  const {
    logIn,
    isLoggedIn,
    setLogIn,
    email,
    setEmail,
    userName,
    setUserName,
    setAvatar,
    password,
    setPassword,
  } = userContext;

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('auth_token');
  // let navigate = useNavigate();
  useEffect(() => {
    if (token) {
      setAccessToken(token);
    }
  }, [token]);

  const getTokenFromUrl = async () => {
    if (!token) {
      return;
      // window.location.href = `${process.env.REACT_APP_REDIRECT_URL}`;
    }
    let playerdata;
    if (token) {
      try {
        const requestBody = {
          auth_token: token,
        };
        const response = await axios.post(
          'https://api.myxogos.com/api/contractor/verify_token.php',

          requestBody,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (!player) {
          playerdata = response.data;
          updatePlayer(response.data);
        }
      } catch (error) {
        console.error('Token error:', error);
      }
      if (token) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/logintoken`,
            { token: token },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (response.data) {
            // Process successful response
            const student = response.data;

            const userData = {
              id: student.std_id,
              name: student.std_name,
              email: student.std_email,
              avatar: student.profile_img,
              // classId: student.classId,
              // schoolId: student.schoolId,
              // cityId: student.cityId,
              // stateId: student.stateId,
              // countryId: student.countryId,
              user_id: playerdata.user_id,
              gameAccessToken: student.accessToken,
              accessToken: token,
              refreshToken: token,
            };

            setError(false);

            localStorage.setItem('userData', JSON.stringify(userData));
            setEmail(student.std_email);
            setUserName(student.std_name);
            setAvatar(student.profile_img);

            // navigate("/", { replace: true });
          } else {
            // Handle other response statuses
            // window.location.href = "https://www.myxogos.com/";
          }
        } catch (error) {
          console.error('Token error:', error);

          // const logedIn = isLoggedIn();
          if (error.response && error.response.status === 401) {
            setError(true);
            window.location.href = 'https://www.myxogos.com/';
          } else {
            window.location.href = 'https://www.myxogos.com/';
          }
        }
      }
    }
  };
  // const auth = isLoggedIn();

  useEffect(() => {
    const auth = localStorage.getItem('userData');
    const authToken = JSON.parse(auth);
    console.log(player);
    if (player) {
      const intervalId = setInterval(async () => {
        const response = await updateTimePlayed(player.user_id, 1);
        console.log(response);
      }, 60 * 1000); // Run every 60 seconds (60000 milliseconds)

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [player]);

  useEffect(() => {
    getTokenFromUrl();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path='/login'
            element={<LoginRoute component={<Login />} />}
            exact
          />
          <Route
            path='/'
            element={<ProtectedRoutes component={<Menu />} />}
            exact
          />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route
            path='/youwin'
            element={<ProtectedRoutes component={<PlayerWin />} />}
          />
          <Route
            path='/youlose'
            element={<ProtectedRoutes component={<PlayerLose />} />}
          />
          <Route
            path='/roundoneover'
            element={<ProtectedRoutes component={<RoundOneFinished />} />}
          />
          <Route
            path='/roundtwoover'
            element={<ProtectedRoutes component={<RoundTwoFinished />} />}
          />
          <Route
            path='/choose-opponent'
            element={<ProtectedRoutes component={<ChooseOpponent />} />}
          />
          <Route path='/catagories' element={<Catagories />} />
          <Route
            path='/select-category'
            element={<ProtectedRoutes component={<SelectCategory />} />}
          />
          {/* <Route path="/waiting" element={<Waiting />} /> */}
          <Route
            path='/game'
            element={<ProtectedRoutes component={<GameParent />} />}
          />
          <Route
            path='/leaderboard'
            element={<ProtectedRoutes component={<LeaderBoard />} />}
          />
          <Route
            path='/profile'
            element={<ProtectedRoutes component={<Profile />} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
