import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [logIn, setLogIn] = useState(false);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [password, setPassword] = useState("");
  const [schoolId, setSchoolId] = useState();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    console.log(user);

    if (user) {
      setUserName(user.name);
      setAvatar(user.avatar);
      setSchoolId(user.schoolId);
    }
  }, []);

  const logOut = () => {
    console.log("logOut Called");
    localStorage.removeItem("userData");
    window.location.href = "https://www.myxogos.com/";
  };

  const isLoggedIn = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (userData?.accessToken) {
      return { login: true, role: userData.role };
    }
    return { login: false };
  };

  return (
    <UserContext.Provider
      value={{
        logIn,
        setLogIn,
        isLoggedIn,
        email,
        setEmail,
        userName,
        setUserName,
        avatar,
        setAvatar,
        password,
        setPassword,
        schoolId,
        logOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
