import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PlayerProvider } from "./Context/PlayerContext";
import { DndProvider } from "react-dnd";
import { RoomProvider } from "./Context/RoomContext";
import { TimeProvider } from "./Context/TimeContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import { UserProvider } from "./Context/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <PlayerProvider>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <RoomProvider>
          <TimeProvider>
            <App />
          </TimeProvider>
        </RoomProvider>
      </DndProvider>
    </PlayerProvider>
  </UserProvider>
);
