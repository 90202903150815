import React, {useState, useEffect, useContext} from "react";
import Login from "./Login";
import Player from "./Player";
// import ChooseOpponent from "./ChooseOpponent";
import RoundOne from "./RoundOne";
import RoundTwo from "./RoundTwo";
import Timer from "./Timer";
import InactivePlayer from "./InactivePlayer";
// import CardSlot from "./CardSlot";
// import Card from "./Card";

// import { cardSlot, cardList, rightOrderList, extraCards } from "./Data";
import {RoomContext} from "../Context/RoomContext";
import {playerWin} from "../api/playerWin";
import {Row, Col, Container, Dropdown, DropdownButton} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import Dropdown from "react-bootstrap/Dropdown";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Game.css";

import PlayerTwo from "./PlayerTwo";
import {UserContext} from "../Context/UserContext";
import {useNavigate} from "react-router-dom";

const Game = () => {
  // const [board, setBoard] = useState(cardSlot);
  // const [cards, setCards] = useState(cardList);
  // const [rightOrder, setRightOrder] = useState(rightOrderList);
  // const [newCards, setNewCards] = useState(extraCards);
  // const [score, setScore] = useState(0);
  // const [roundOne, setRoundOne] = useState(true);
  // const [roundTwo, setRoundTwo] = useState(false);
  // const [inactivePlayer, setInactiveplayer] = useState(true);

  // const [counter, setCounter] = useState(90);

  // const [playerName, setPlayerName]
  // const [playerAvatar, setPlayerAvatar] = useState()

  let roomContext = useContext(RoomContext);
  let userContext = useContext(UserContext);

  const {
    win,
    room,
    joinRoom,
    clientId,
    singlePlayer,
    setSinglePlayer,
    opponentJoined,
    opponentId,
    opponentName,
    opponentAvatar,
    score,
    setScore,
    turn,
    changeTurn,
    setRound,
    roundOne,
    setRoundOne,
    roundTwo,
    setRoundTwo,
    cardsList,
    clientStars,
    opponentStars,
    opponentScore,
    setOpponentScore,
    opponentLeft,
    selectedCategory,
    setSelectedCategory,
    leaveAfterGameStart,
    setStartRoundTwoTimer,
  } = roomContext;

  const {userName, avatar} = userContext;

  const navigate = useNavigate();

  useEffect(() => {
    window.onbeforeunload = function () {
      return "";
    };
  }, []);

  console.log("OPPONENT_SCORE", opponentScore);

  const notify = response => {
    if (response === "quit") {
      toast.error(
        <center>
          <h4>Quit Game</h4>
          {console.log("quiting")}
          {/* <button type="button" className="btn btn-primary mx-10">
              cancel
            </button> */}
          <button
            type="button"
            className="btn btn-danger"
            style={{marginTop: "20px"}}
            onClick={handleQuitGame}
          >
            confirm
          </button>
        </center>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        }
      );
    }
  };

  // console.log("cardlist", cardsList);
  // console.log("inactivecards", inactiveCardsForStars);

  // useEffect(() => {
  //   if (turn === clientId) {
  //     counter === 0 && changeTurn();
  //     counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  //   }
  // }, [counter, turn]);

  // useEffect(() => {
  //   if ((turn === clientId && counter === 0) || counter < 90) {
  //     setCounter(90);
  //   }
  // }, [turn]);

  console.log("selectedCategory", selectedCategory);

  useEffect(() => {
    if (!room) {
      navigate("/");
    }
  }, [room]);

  console.log("opponent left", opponentLeft);

  if (opponentLeft === true) {
    playerWin(score, selectedCategory)
      .then(res => {
        console.log("res from game", res);
        if (res.status === 200) {
          setScore(0);
          setOpponentScore(0);
          setSelectedCategory("");
        }
      })
      .catch(error => {
        console.log(error);
      });

    console.log("opponent-left", opponentLeft);
    navigate("/youwin", {replace: true});

    // if (win) {
    //   navigate("/youwin", { replace: true });
    // } else {
    //   navigate("/youlose", { replace: true });
    // }

    // joinRoom(selectedCategory.category);
    // navigate("/choose-opponent", { replace: true });
  }

  const handleQuitClicked = () => {
    notify("quit");
  };

  const handleQuitGame = () => {
    leaveAfterGameStart();
    setSinglePlayer(false);
    setScore(0);
    setOpponentScore(0);
    setStartRoundTwoTimer(false);
    navigate("/select-category");
  };

  console.log("SCORES", score, opponentScore);

  console.log("CHECK-TURN", clientId, opponentId, turn);

  const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <a
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      &#x25bc;
      {children}
    </a>
  ));

  return (
    <Container
      fluid
      className={
        clientId !== turn
          ? "inactiveContainerPadding"
          : "activeContainerPadding"
      }
    >
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: "30px",
          }}
          className="col-12"
        >
          <h1 className="mainTitle">New Game - {selectedCategory?.category}</h1>

          <DropdownButton
            align="end"
            title=""
            id="dropdown-menu-align-end"
            variant="outline-warning"
            size="sm"
            // className="quitBtn"
          >
            <Dropdown.Item eventKey="1" onClick={handleQuitClicked}>
              Quit Game
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <Row>
        <Col className="col-4 players">
          <Player
            name={userName}
            avatar={avatar}
            stars={clientStars}
            score={score}
          />
        </Col>
        <Col className="col-4 score-vs-align">
          <div>
            {!singlePlayer && <h1 className="vs">VS</h1>}
            {/* <h3 className="score">SCORE : {score}</h3> */}
            {!singlePlayer && (
              <h3 className="score">
                {roundOne
                  ? clientId === turn
                    ? "Your Turn"
                    : `Opponent's Turn`
                  : "Place Cards In Right Order"}
              </h3>
            )}
            <h3 className="score">
              TIME :{" "}
              <Timer
                turn={turn}
                clientId={clientId}
                changeTurn={changeTurn}
                roundOne={roundOne}
                roundTwo={roundTwo}
              />
            </h3>
          </div>
        </Col>
        <Col className="col-4 players">
          {!singlePlayer && (
            <Player
              name={opponentName}
              avatar={opponentAvatar}
              stars={opponentStars}
              score={opponentScore}
            />
          )}
        </Col>
      </Row>

      {singlePlayer
        ? clientId &&
          roundOne && (
            <RoundOne
              score={score}
              setScore={setScore}
              roundOne={roundOne}
              setRoundOne={setRoundOne}
              roundTwo={roundTwo}
              setRoundTwo={setRoundTwo}
              setRound={setRound}
            />
          )
        : clientId === turn &&
          roundOne && (
            <RoundOne
              score={score}
              setScore={setScore}
              roundOne={roundOne}
              setRoundOne={setRoundOne}
              roundTwo={roundTwo}
              setRoundTwo={setRoundTwo}
              setRound={setRound}
            />
          )}

      {roundTwo && <RoundTwo roundTwo={roundTwo} />}

      {clientId !== turn && roundOne && !singlePlayer && <InactivePlayer />}

      {/* <center>
        <button
          className="logout-btn"
          style={{ marginTop: "10px" }}
          onClick={handleQuitClicked}
        >
          QUIT
        </button>
      </center> */}

      <ToastContainer />
    </Container>
  );
};

export default Game;
