import React, {createContext, useState, useEffect, useContext} from "react";
import {
  cardSlot,
  // cardList,
  roundTwoCardSlot,
  initialStars,
} from "../components/Data";
import * as Colyseus from "colyseus.js";
import {UserContext} from "./UserContext";

export const RoomContext = createContext();

export const RoomProvider = ({children}) => {
  const [room, setRoom] = useState(null);
  const [client, setClient] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [opponentJoined, setOpponentJoined] = useState(false);
  const [opponentLeft, setOpponentLeft] = useState(false);
  const [clientId, setClientId] = useState();
  const [opponentId, setOpponentId] = useState();
  const [opponentName, setOpponentName] = useState("");
  const [opponentAvatar, setOpponentAvatar] = useState();
  const [clientCards, setCLientCards] = useState();

  const [singlePlayer, setSinglePlayer] = useState(false);
  const [startSinglePlayerGame, setStartSinglePlayerGame] = useState(false);

  // const [opponenttCards, setOpponentCards] = useState();
  // const [cardData, setCardData] = useState();

  const [client1, setClient1] = useState();
  const [client2, setClient2] = useState();
  const [turn, setTurn] = useState();
  const [answer, setAnswer] = useState();

  const [score, setScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);

  const [cardsList, setCardsList] = useState();
  const [roundTwoBoard, setRoundTwoBoard] = useState([...roundTwoCardSlot]);
  const [startRoundTwoTimer, setStartRoundTwoTimer] = useState(false);
  const [roundTwoCardList, setRoundTwoCardList] = useState([]);
  const [inactivePlayerCards, setInactivePlayerCards] = useState();
  const [clientStars, setClientStars] = useState([...initialStars]);
  const [opponentStars, setOpponentStars] = useState([...initialStars]);

  const [newCard, setNewCard] = useState();
  const [referenceCard, serReferenceCard] = useState();

  // const [categories, setCategories] = useState();
  const [checkDragging, setCheckDragging] = useState();

  const [activePlayerBoard, setActivePlayerBoard] = useState([...cardSlot]);
  const [inactivePlayerBoard, setInactivePlayerBoard] = useState([...cardSlot]);

  const [cardCounter, setCardCounter] = useState(0);

  // const [roundOneDeckCardsLength, setRoundOneDeckCardsLength] = useState("5");
  // const [roundOneBoardIndex, setRoundOneBoardIndex] = useState();

  const [startRoundOne, setStartRoundOne] = useState(false);
  const [startRoundTwo, setStartRoundTwo] = useState(false);

  const [roundOneFinished, setRoundOneFinished] = useState();
  const [roundTwoFinished, setRoundTwoFinished] = useState(false);

  const [roundTwoCardsSequence, setRoundTwoCardsSequence] = useState("");
  // const [roundTwoFinishedByPlayerOne, setRoundTwoFinishedByPlayerOne] = useState()
  const [lastTurn, setLastTurn] = useState(false);

  const [roundOne, setRoundOne] = useState(false);
  const [roundTwo, setRoundTwo] = useState(false);
  const [round, setRound] = useState();

  const [win, setWin] = useState(false);

  const [rematch, setRematch] = useState(false);
  const [startRematch, setStartRematch] = useState();
  const [restartGame, setRestartGame] = useState(false);

  // const [roundOneCardPlaced, setRoundOneCardPlaced] = useState(false);

  // const [inactivePlayerModal, setInactiveplayerModal] = useState();

  // User Context
  const userContext = useContext(UserContext);
  const {userName, avatar, schoolId} = userContext;

  useEffect(() => {
    // setClient(new Colyseus.Client("wss://op0iax.colyseus.de"));
    // setClient(new Colyseus.Client("wss://oqjymf.us-east-vin.colyseus.net"));
    setClient(new Colyseus.Client("wss://timequest.rocks/colyseuss"));
    // setClient(new Colyseus.Client("wss://timequest.huntthepast.com/colyseuss"));
  }, []);

  const joinSinglePlayerRoom = slectectedItem => {
    console.log("joining room");
    console.log(slectectedItem);
    client
      .joinOrCreate("singleplayer", {
        mode: slectectedItem,
        userName: userName,
        avatar: avatar,
        schoolId,
      })
      .then(room => {
        setClientId(room.sessionId);
        setRoom(room);
        setStartSinglePlayerGame(true);
        console.log(room);
      })
      .catch(error => {
        console.log("Join Error", error);
      });
  };

  const joinRoom = slectectedItem => {
    console.log("joining room");
    console.log(slectectedItem);
    client
      .joinOrCreate("multiplayer", {
        mode: slectectedItem,
        userName: userName,
        avatar: avatar,
        schoolId,
      })
      .then(room => {
        setClientId(room.sessionId);
        console.log("Room Joined Successfully");
        setRoom(room);
        room.onMessage("joined", message => {
          console.log("joined message", message);
          setClient1(message.client1);
          setClient2(message.client2);

          let clientId = room.sessionId;
          console.log("client", clientId);
          let opponent =
            message.client1 === clientId ? message.client2 : message.client1;

          console.log("opponent", opponent);
          let opponentData = message.players_info.filter(
            item => item[opponent]
          );
          setOpponentJoined(true);
          // console.log("opponentData", opponentData[0][opponent].userName);
          setOpponentName(opponentData[0][opponent].userName);
          setOpponentAvatar(opponentData[0][opponent].avatar);
          setTurn(message.turn);
        });
      })
      .catch(e => {
        console.log("JOIN ERROR", e);
      });
  };

  useEffect(() => {
    if (clientId === client1) {
      setOpponentId(client2);
    } else {
      setOpponentId(client1);
    }
  }, [client1, client2, clientId]);

  if (room && opponentId) {
    room.onMessage("onRound_One_Cards", message => {
      console.log("onRound_One_Cards", message);
      console.log("client cards from server", message[clientId]?.cards);
      console.log("opponent cards from server", message[opponentId]?.cards);
      console.log(
        "refrence cards from server",
        message[clientId].reference_card
      );

      setCardsList([...message[clientId].cards]);
      setInactivePlayerCards([...message[clientId].cards]);
      setNewCard(message[clientId].alternative_card);
      serReferenceCard({...message[clientId].reference_card});
      activePlayerBoard[2] = {...message[clientId].reference_card};
      inactivePlayerBoard[2] = {...message[clientId].reference_card};

      setTurn(message.turn);

      console.log("PLAYER_BOARDS", activePlayerBoard, inactivePlayerBoard);

      setActivePlayerBoard([...activePlayerBoard]);
      setInactivePlayerBoard([...activePlayerBoard]);

      setOpponentJoined(true);
      setStartRoundOne(true);
      setRoundOne(true);
      setRoundTwo(false);
      setRestartGame(true);

      setTimeout(() => {
        setRestartGame(true);
      }, 5000);
      // opponent_cards = message[oponentID]
    });

    room.onMessage("onChange_Turn", message => {
      console.log("onChange_Turn", message);

      if (message[clientId]) {
        setClientStars(message[clientId]);
      }

      if (message[opponentId]) {
        setOpponentStars(message[opponentId]);
      }

      setInactivePlayerCards([...message[clientId]]);

      if (message.round === "roundTwo") {
        setInactivePlayerBoard([...message.message?.board_cards]);
      }

      // if (message.round === "roundOne") {
      // setInactivePlayerCards([...message[clientId]]);
      // } else if (message.round === "roundTwo") {
      // setInactivePlayerCards([...message[opponentId]]);
      // setInactivePlayerBoard([...message.message.boardArray]);
      //  ------------------------------------------------
      // if (
      //   message[clientId]?.length === 0 &&
      //   message[opponentId]?.length === 0
      // ) {
      //   setRoundTwoFinished(true);
      // }
      // ----------------------------------------------
      //   let list = message.boardsArray;
      //   let newList = list.map((card) => {
      //     if (card.url !== "/images/card-slot.png") {
      //       return {
      //         ...card,
      //         url: "/images/question-card.png",
      //         name: "",
      //         date: "",
      //       };
      //     }
      //     return card;
      //   });
      //   setInactivePlayerBoard(newList);
      // setInactivePlayerCards([...message[opponentId]]);
      // }

      setTurn(message.turn);

      // if (message.turn === clientId) {
      //   // console.log("HELOOOOO", cardList);
      //   room.send("share_cards", { deck_cards: clientCards });
      // }

      // if (message.round === "roundOne") {
      //   setRoundOne(true);
      //   setRoundTwo(false);
      // } else if (message.round === "roundTwo") {
      //   setRoundOne(false);
      //   setRoundTwo(true);
      // }
    });

    // room.onMessage("onShare_cards", (message) => {
    //   console.log("onShare_cards", message);
    //   // setInactivePlayerCards([...message.deck_cards]);
    //   // setOpponentScore()
    // });

    room.onMessage("on_Cancel_Before", message => {
      console.log("on_Cancel_Before", message);
      setOpponentJoined(false);
      setStartRoundOne(false);
      setClientId();
      setClient1();
      setClient2();
      setOpponentId("");
      setOpponentName("");
      setOpponentAvatar();
      setCardsList();
      setOpponentLeft(true);
      setActivePlayerBoard([...cardSlot]);

      setTimeout(() => {
        setOpponentLeft(false);
      }, 3000);
    });

    room.onMessage("on_Cancel_Between", message => {
      console.log("on_Cancel_Between", message);
      setOpponentJoined(false);
      setStartRoundOne(false);
      setClientId();
      setClient1();
      setClient2();
      setOpponentId("");
      setOpponentName("");
      setOpponentAvatar();
      setCardsList();
      setOpponentLeft(true);
      setActivePlayerBoard([...cardSlot]);

      setTimeout(() => {
        setOpponentLeft(false);
      }, 3000);
    });

    room.onMessage("onRound_One_Result", message => {
      console.log("onRound_One_Result", message);
      setActivePlayerBoard([...message.index]);
      setInactivePlayerBoard([...message.index]);
      console.log("TURN", turn, "CLIENT", clientId);
      setOpponentScore(message[opponentId]);
      // if (turn === opponentId) {
      //   if (message.result === "correct") {
      //     setOpponentScore(message.message?.opponent_score);
      //   }
      // }
      // setInactivePlayerCards(message.deck_cards);

      // console.log("deckCards", turn, clientId);
    });

    room.onMessage("onRound_result", message => {
      console.log("checknewcard", message);
      setNewCard(message.alternative_card);
    });

    room.onMessage("onRound_one_over", message => {
      console.log("onRound_one_over", message);
      setRoundOneFinished(true);
    });

    room.onMessage("onRound_Two_Cards", message => {
      console.log("onRound_Two_Cards", message);
      setRoundTwoCardList([...message[clientId].cards]);
      setInactivePlayerCards([...message[opponentId].cards]);
      setInactivePlayerBoard([...roundTwoCardSlot]);

      setTimeout(() => {
        setStartRoundTwoTimer(true);
      }, 1000);

      setRoundOne(false);
      setRoundTwo(true);
      setStartRoundOne(false);
      setStartRoundTwo(true);
      setClientStars([...message[clientId].cards]);
      setOpponentStars([...message[opponentId].cards]);
    });

    room.onMessage("onRoundTwo_move", message => {
      console.log("onRoundTwo_move", message);
      let list = message.index;
      let newList = list.map(card => {
        if (card.url !== "/images/card-slot.png") {
          return {
            ...card,
            url: "/images/question-card.png",
            name: "",
            date: "",
          };
        }

        return card;
      });
      let newCards = message.deck_cards;
      console.log("newList", newList);
      // setInactivePlayerBoard(newList);
      // setInactivePlayerCards(newCards);
      // setOpponentScore(message.message.opponentScore);
    });

    room.onMessage("roundTwoEndedFirst", message => {
      console.log("roundTwoEndedFirst", message);
      setLastTurn(true);
    });

    room.onMessage("roundTwoEnded", message => {
      console.log("roundTwoEnded", message);
      console.log("opponentScore", message[opponentId]);
      setOpponentScore(message[opponentId]);
      setRoundTwoFinished(true);
    });

    room.onMessage("onRematch", message => {
      console.log("onRematch", message);
      setRematch(true);

      setTimeout(() => {
        setRematch(false);
      }, 5000);
    });

    room.onMessage("onConfirm_Rematch", message => {
      console.log("onConfirm_Rematch", message);
      if (message.confirm_rematch === true) {
        setStartRematch(true);
        setClientStars([...initialStars]);
        setOpponentStars([...initialStars]);
        setActivePlayerBoard([...cardSlot]);
        setInactivePlayerBoard([...cardSlot]);
        // room.send("round_one", {
        //   categoryname: selectedCategory.category,
        //   categoryid: JSON.stringify(selectedCategory.id),
        //   sessionid: "todo",
        //   roomId: room.id,
        //   round: round,
        //   opponent_id: opponentId,
        // });
        setTimeout(() => {
          setStartRematch();
        }, 1000);
      } else {
        setRoom(null);
        setScore(0);
        setOpponentScore(0);
        setSelectedCategory("");
        setStartRematch(false);
        setOpponentJoined(false);
        setClientId();
        setClient1();
        setClient2();
        setOpponentId();
        setOpponentName("");
        setOpponentAvatar();

        // setTimeout(() => {
        //   setStartRematch();
        // }, 5000);
      }
    });

    room.onMessage("onQuit_Game", message => {
      setOpponentJoined(false);
      setOpponentLeft(true);
      setOpponentId("");
      setOpponentName("");
      setOpponentAvatar();
    });
  } else if (room && singlePlayer) {
    console.log("calling round one cards event");
    room?.onMessage("onRound_One_Cards", message => {
      console.log("onRound_One_Cards", message);
      console.log(message[clientId].cards);
      setCardsList([...message[clientId].cards]);
      setNewCard(message[clientId].alternative_card);
      serReferenceCard({...message[clientId].reference_card});
      activePlayerBoard[2] = {...message[clientId].reference_card};
      setActivePlayerBoard([...activePlayerBoard]);
      setStartRoundOne(true);
      setRoundOne(true);
      setRoundTwo(false);
      setTimeout(() => {
        setRestartGame(true);
      }, 5000);
    });

    room.onMessage("onRound_One_Result", message => {
      console.log("onRound_One_Result", message);
      setNewCard(message.alternative_card);
    });

    room.onMessage("onRound_one_over", message => {
      console.log("onRound_one_over", message);
      setRoundOneFinished(true);
    });

    room.onMessage("onRound_Two_Cards", message => {
      console.log("onRound_Two_Cards", message);
      setRoundTwoCardList([...message[clientId].cards]);

      setTimeout(() => {
        setStartRoundTwoTimer(true);
      }, 1000);

      setRoundOne(false);
      setRoundTwo(true);
      setStartRoundOne(false);
      setStartRoundTwo(true);
      setClientStars([...message[clientId].cards]);
    });

    room.onMessage("on_Cancel_Between", message => {
      console.log("on_Cancel_Between", message);
      setStartRoundOne(false);
      setClientId();
      setClient1();
      setClient2();
      setCardsList();
      setActivePlayerBoard([...cardSlot]);
    });
  }

  // ----------ACTIONS-------------

  const onRoundOneDrop = (
    checkAnswer,
    updatedCards,
    updatedBoard,
    dropedCard,
    // updatedScore,
    opponentScore
  ) => {
    if (checkAnswer === "correct") {
      room.send("correct", {
        deck_cards: updatedCards,
        place_index: updatedBoard,
        turn: turn,
        card_data: dropedCard,
        result: checkAnswer,
        sessionid: "todo",
        category: selectedCategory.category,
        categoryid: JSON.stringify(selectedCategory.id),
        opponent_id: opponentId,
        round: "roundOne",
        roomId: room.id,
        // opponent_score: opponentScore + 10,
        // scoreOfOpponent: opponentScore,
      });
    } else if (checkAnswer === "wrong") {
      room.send("wrong", {
        deck_cards: updatedCards,
        place_index: updatedBoard,
        reference_cards: referenceCard,
        card_data: dropedCard,
        turn: turn,
        result: checkAnswer,
        sessionid: "todo",
        category: selectedCategory.category,
        categoryid: JSON.stringify(selectedCategory.id),
        opponent_id: opponentId,
        round: "roundOne",
        roomId: room.id,
      });
    }
  };

  const leaveBeforeGameStart = () => {
    console.log("before", room);
    room &&
      room.send("cancel_game", {
        before_game: true,
      });

    opponentJoined && setOpponentJoined(false);
    opponentId && setOpponentId();
    opponentName && setOpponentName("");
    opponentAvatar && setOpponentAvatar();
  };

  const leaveAfterGameStart = () => {
    console.log("after");
    room.send("cancel_game", {
      before_game: false,
    });
  };

  const roundOneOver = () => {
    console.log("calling roundone over");
    room.send("round_one_over", {
      round_one_over: true,
    });
  };

  const roundTwoStart = () => {
    console.log("roundTwoSTart", clientId, opponentId, turn);
    room.send("round_two", {
      categoryname: selectedCategory.category,
      categoryid: JSON.stringify(selectedCategory.id),
      sessionid: "todo",
      roomId: room.id,
      opponent_id: opponentId,
      round: "roundTwo",
    });
  };

  const onRoundTwoDrop = (index, deckCards, score) => {
    room.send("round_two_moves", {
      deck_cards: deckCards,
      place_index: index,
      // opponentScore: score,
      card_data: "",
      turn: turn,
      sessionid: "todo",
      category: selectedCategory.category,
      categoryid: JSON.stringify(selectedCategory.id),
      opponent_id: opponentId,
      round: "roundTwo",
      roomId: room.id,
    });
  };

  const roundTwoCompletedByPlayerOne = playerScore => {
    console.log("roundTwoCompletedByPlayer One Called");
    room.send("player_exit", {
      opponent_id: opponentId,
      score: playerScore,
    });
  };

  const roundTwoCompletedByPlayerTwo = playerScore => {
    console.log("roundTwoCompletedByPlayer Two Called");
    room.send("roundtwo_finished_second", {
      opponent_id: opponentId,
      score: playerScore,
    });
  };

  const shareData = userScore => {
    // room.send("share_cards", {
    //   playerScore: userScore,
    // });
  };

  const playerRematch = () => {
    room.send("rematch");
  };

  const confirmRematch = () => {
    room.send("confirm_rematch");
  };

  const cancelRematch = () => {
    room.send("cancel_rematch");
  };

  const changeTurn = (cardsArray, inactivePlayerBoard, inactivePlayerScore) => {
    console.log(
      "CHANGED TURN CALLED",
      cardsArray,
      inactivePlayerScore,
      inactivePlayerBoard,
      inactivePlayerCards
    );
    room.send("change_turn", {
      roomId: room.id,
      opponent_id: opponentId,
      round: round,
      deck_cards: cardsArray,
      board_cards: inactivePlayerBoard,
      opponent_score: inactivePlayerScore,
      // boardArray: inactivePlayerBoard && inactivePlayerBoard,
    });
  };

  const finishGame = () => {
    room?.send("quit_game");
  };

  // const roundTwoChangeTurn = (cardsArray, boardsArray) => {
  //   room.send("change_turn", {
  //     roomId: room.id,
  //     opponent_id: opponentId,
  //     round: round,
  //     deck_cards: cardsArray,
  //     boardsArray: boardsArray,
  //   });
  // };

  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem("userData"));

  //   console.log("userData", userData);

  //   const accessToken = userData && userData.accessToken;

  //   var axios = require("axios");

  //   var config = {
  //     method: "get",
  //     url: "https://op0iax.colyseus.de/category",
  //     headers: { accessToken },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //       if (response.status === 200) {
  //         console.log("RESPONSE", response.data);
  //         setCategories(response.data);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  // useEffect(() => {
  //   setInactivePlayerBoard(inactivePlayerBoard);
  // }, [inactivePlayerBoard]);

  useEffect(() => {
    if (startRematch === true) {
      console.log("starting round one");
      room.send("round_one", {
        categoryname: selectedCategory.category,
        categoryid: JSON.stringify(selectedCategory.id),
        sessionid: "todo",
        roomId: room.id,
        round: "roundOne",
        opponent_id: opponentId,
      });
    }
  }, [startRematch]);

  useEffect(() => {
    if (startSinglePlayerGame) {
      console.log("starting round one");
      room.send("round_one", {
        categoryname: selectedCategory.category,
        categoryid: JSON.stringify(selectedCategory.id),
        sessionid: room.sessionId,
        roomId: room.id,
        round: "roundOne",
        opponent_id: opponentId,
      });

      setStartSinglePlayerGame(false);
    }
  }, [startSinglePlayerGame]);

  useEffect(() => {
    if (opponentId) {
      if (turn === clientId) {
        room?.send("round_one", {
          categoryname: selectedCategory.category,
          categoryid: JSON.stringify(selectedCategory.id),
          sessionid: "todo",
          roomId: room.id,
          round: round,
          opponent_id: opponentId,
        });
      }
    }
  }, [opponentId]);

  console.log(opponentJoined);

  return (
    <RoomContext.Provider
      value={{
        room,
        setRoom,
        joinRoom,
        opponentJoined,
        setOpponentJoined,
        opponentLeft,
        selectedCategory,
        setSelectedCategory,
        leaveBeforeGameStart,
        leaveAfterGameStart,
        singlePlayer,
        setSinglePlayer,
        clientId,
        setClientId,
        setClient1,
        setClient2,
        setClientStars,
        opponentId,
        setOpponentId,
        opponentName,
        setOpponentName,
        opponentAvatar,
        setOpponentAvatar,
        setOpponentStars,
        checkDragging,
        setCheckDragging,
        turn,
        round,
        roundOne,
        setRoundOne,
        roundTwo,
        setRoundTwo,
        startRoundOne,
        setStartRoundOne,
        startRoundTwo,
        setStartRoundTwo,
        setTurn,
        setRound,
        cardsList,
        setCardsList,
        setCLientCards,
        roundTwoCardList,
        setRoundTwoCardList,
        clientStars,
        opponentStars,
        score,
        setScore,
        // categories,
        roundTwoBoard,
        setRoundTwoBoard,
        activePlayerBoard,
        setActivePlayerBoard,
        inactivePlayerCards,
        setInactivePlayerCards,
        setInactivePlayerBoard,
        inactivePlayerBoard,
        // inactivePlayerModal,
        // setInactiveplayerModal,
        onRoundOneDrop,
        onRoundTwoDrop,
        playerRematch,
        changeTurn,
        // roundTwoChangeTurn,
        answer,
        setAnswer,
        newCard,
        roundTwoStart,
        cardCounter,
        setCardCounter,
        shareData,
        win,
        setWin,
        rematch,
        confirmRematch,
        cancelRematch,
        startRematch,
        restartGame,
        roundOneOver,
        roundOneFinished,
        setRoundOneFinished,
        roundTwoFinished,
        setRoundTwoFinished,
        lastTurn,
        setLastTurn,
        roundTwoCompletedByPlayerOne,
        roundTwoCompletedByPlayerTwo,
        opponentScore,
        setOpponentScore,
        startRoundTwoTimer,
        setStartRoundTwoTimer,
        joinSinglePlayerRoom,
        roundTwoCardsSequence,
        setRoundTwoCardsSequence,
        finishGame,
      }}
    >
      {children}
    </RoomContext.Provider>
  );
};
