import React from "react";
import "../index.css";

const ResetPasswordForm = ({ resetToken }) => {
  return (
    <div>
      <form className="loginForm" autoComplete="off">
        <div style={{ width: "62%", margin: "15px auto" }}>
          <img style={{ width: "100%" }} src="/images/reset-pass.png" />
        </div>
        <input
          type="password"
          placeholder="Previous Password"
          style={{
            width: "60vmin",
            height: "9vmin",
            paddingLeft: "10vmin",
            fontSize: "4vmin",
            background: "url(/images/input-field.png)",
            backgroundColor: "transparent",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            border: "none",
          }}
        />
        <br />

        <input
          type="password"
          placeholder="New Password"
          style={{
            width: "60vmin",
            height: "9vmin",
            paddingLeft: "10vmin",
            fontSize: "4vmin",
            background: "url(/images/input-field.png)",
            backgroundColor: "transparent",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            border: "none",
          }}
        />
        <br />

        <input
          type="password"
          placeholder="Retype New Password"
          style={{
            width: "60vmin",
            height: "9vmin",
            paddingLeft: "10vmin",
            fontSize: "4vmin",
            background: "url(/images/input-field.png)",
            backgroundColor: "transparent",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            border: "none",
          }}
        />

        <button
          type="submit"
          style={{
            width: "23%",
            padding: "0",
            border: "none",
            backgroundColor: "transparent",
          }}
        >
          <img style={{ width: "100%" }} src="/images/submit-btn.png" />
        </button>

        {/* <button style={{width: "35%", padding: "0", border: "none", backgroundColor: "transparent"}}>
                <img style={{width: "100%"}} src="/images/forgot-pass-btn.png" />
            </button>

            <button style={{width: "35%", padding: "0", border: "none", backgroundColor: "transparent"}}>
                <img style={{width: "100%"}} src="/images/new-acc-btn.png"/>
            </button> */}
      </form>
    </div>
  );
};

export default ResetPasswordForm;
