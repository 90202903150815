export const NewPassword = async (userId, newPassword, resetToken) => {
  const axios = require("axios");

  let res;

  try {
    const data = {
      user_id: userId,
      new_password: newPassword,
      reset_token: resetToken,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/changepassword`,
      headers: {},
      data: data,
    };

    res = await axios(config);
  } catch (error) {
    console.log(error);
    throw error;
  }

  return await res;
};
