import React, { useState, useContext, useEffect } from "react";
import { RoomContext } from "../Context/RoomContext";
import { useNavigate } from "react-router-dom";
import CardSlot from "./CardSlot";
import Card from "./Card";
import { Row } from "react-bootstrap";
import "./Game.css";

const InactivePlayer = () => {
  const roomContext = useContext(RoomContext);
  const {
    roundOne,
    roundTwo,
    inactivePlayerCards,
    inactivePlayerBoard,
    roundOneFinished,
    setRoundOneFinished,
    roundTwoFinished,
    setRoundTwoFinished,
    setLastTurn,
  } = roomContext;

  let navigate = useNavigate();

  const [cards, setCards] = useState([...inactivePlayerCards]);
  const [board, setBoard] = useState([...inactivePlayerBoard]);

  useEffect(() => {
    console.log("setting inactive player board");
    setCards([...inactivePlayerCards]);
  }, [inactivePlayerCards]);

  console.log("inactivePlayerBoard", inactivePlayerBoard);

  useEffect(() => {
    setBoard([...inactivePlayerBoard]);
  }, [inactivePlayerBoard]);

  useEffect(() => {
    if (roundOneFinished === true) {
      navigate("/roundoneover", { replace: true });
      setRoundOneFinished();
    }
  }, [roundOneFinished]);

  useEffect(() => {
    if (roundTwoFinished === true) {
      navigate("/roundtwoover", { replace: true });
      setTimeout(() => {
        setRoundTwoFinished(false);
        setLastTurn(false);
      }, 2000);
    }
  }, [roundTwoFinished]);

  console.log("roundOneFinished", roundOneFinished);

  return (
    <div style={{ padding: "0" }}>
      <Row>
        {roundOne && (
          <h1 className="round" style={{ marginBottom: "3vmin" }}>
            Round-1
          </h1>
        )}
        {roundTwo && (
          <h1 className="round" style={{ marginBottom: "3vmin" }}>
            Round-2
          </h1>
        )}
      </Row>
      <Row
        className="card-slot-sub-container"
        style={{ display: "flex", flexWrap: "nowrap" }}
      >
        {board.map((picture) => {
          return (
            <CardSlot
              key={picture?.id}
              id={picture?.id}
              name={picture?.name}
              url={picture?.url}
              date={
                roundOne
                  ? picture?.date
                  : inactivePlayerCards.length === 0
                  ? picture.date
                  : ""
              }
              dateType={picture?.dateType}
              cardDrop={picture?.canDrop}
              board={board}
            />
          );
        })}
      </Row>
      <div style={{ height: "23vmin" }}>
        <h2
          className="title"
          style={{ marginTop: roundOne ? "4vmin" : "", marginBottom: "2vmin" }}
        >
          Place your cards in correct order
        </h2>

        <div
          className="card-slot"
          style={{
            marginBottom: "5px",
            padding: "0 20px",
            backgroundColor: "transparent",
          }}
        >
          {cards &&
            cards.map((picture, index) => {
              return (
                <Card
                  key={picture?.id}
                  id={picture?.id}
                  // name={picture.name}
                  // url="/images/question-card.png"
                  url={picture?.url}
                  // date={picture.date}
                  index={index}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default InactivePlayer;
