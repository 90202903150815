export const fetchUsersScore = async () => {
  // console.log();

  let axios = require("axios");

  let config = {
    method: "get",
    url: `https://lightninground.rocks/api/UserScore`,
    headers: {},
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res, "Users Score");
  } catch (error) {
    err = error;
  }

  return res || err;
};

export const fetchTotalCollectCoins = async () => {
  // console.log();

  let axios = require("axios");

  let config = {
    method: "get",
    url: `https://lightninground.rocks/api/totalCollectedCoins`,
    headers: {},
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res, "Total Collect Coins");
  } catch (error) {
    err = error;
  }

  return res || err;
};

export const fetchTotalCollectedPointsByCategory = async () => {
  // console.log();

  let axios = require("axios");

  let config = {
    method: "get",
    url: `https://lightninground.rocks/api/UserScoreByCategory`,
    headers: {},
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res, "Total Collected Points By Category");
  } catch (error) {
    err = error;
  }

  return res || err;
};

export const fetchTotalTimePlayedGame = async () => {
  // console.log();

  let axios = require("axios");

  let config = {
    method: "get",
    url: `https://lightninground.rocks/api/timeGamgePlayed`,
    headers: {},
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res, "Total Time Played Game");
  } catch (error) {
    err = error;
  }

  return res || err;
};

export const updateCoins = async (user_id, coins) => {
  let axios = require("axios");

  let config = {
    method: "post",
    url: `https://api.myxogos.com/api/contractor/update_coins.php`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      api_key: process.env.REACT_APP_PORTAL_API,
      table_id: process.env.REACT_APP_PORTAL_TABLE_ID,
      user_id: user_id,
      coins: coins,
    },
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res.data, "Update Coins Response");
  } catch (error) {
    err = error;
    console.error(err);
  }

  return res ? res.data : err;
};

export const updateTimePlayed = async (user_id, time_played) => {
  let axios = require("axios");

  let config = {
    method: "post",
    url: "https://api.myxogos.com/api/contractor/update_time.php",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      api_key: process.env.REACT_APP_PORTAL_API,
      table_id: process.env.REACT_APP_PORTAL_TABLE_ID,
      user_id: user_id,
      time_played: time_played,
    },
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res.data, "Update Time Played Response");
  } catch (error) {
    err = error;
    console.error(err);
  }

  return res ? res.data : err;
};

export const verifyToken = async (accessToken) => {
  let axios = require("axios");

  let config = {
    method: "post",
    url: `https://api.myxogos.com/api/contractor/verify_token.php`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      api_key: process.env.REACT_APP_PORTAL_API,
      access_token: accessToken,
    },
  };

  let res;
  let err;

  try {
    res = await axios(config);
    console.log(res.data, "Verify Token Response");
  } catch (error) {
    err = error;
    console.error(err);
  }

  return res ? res.data : err;
};
