import React, {useState, useEffect, useContext} from "react";
import {RoomContext} from "../Context/RoomContext";
import {useNavigate} from "react-router-dom";
import {Button} from "bootstrap";
import Select from "react-select";

const Catagories = () => {
  const roomContext = useContext(RoomContext);

  const {joinRoom, selectedCategory, setSelectedCategory, client2} =
    roomContext;

  const [category, setCategory] = useState();

  const [data, setData] = useState([]);

  let navigate = useNavigate();

  const startGame = () => {
    joinRoom();
    navigate("/waiting", {replace: true});
  };

  //   useEffect(() => {
  //     client2 && navigate("/game", { replace: true });
  //     console.log(client2);
  //   }, [client2]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    console.log("userData", userData);

    const accessToken = userData && userData.accessToken;

    var axios = require("axios");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/category`,
      headers: {accessToken},
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setData([
          ...response.data.map(item => {
            return {value: item.id, label: item.category};
          }),
        ]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleChange = e => {
    setSelectedCategory({id: e.value, name: e.label});
  };

  return (
    <div style={{width: "50%", margin: "20px auto"}}>
      <Select
        placeholder="Select Catagory"
        options={data}
        value={data && data.find(obj => obj.value === selectedCategory)}
        onChange={handleChange}
      />

      <button
        style={{
          display: "flex",
          alignItems: "center",
          margin: "20px auto",
        }}
        type="button"
        className="btn btn-primary"
        onClick={selectedCategory && startGame}
      >
        START
      </button>
    </div>
  );
};

export default Catagories;
