import React, { useContext } from "react";
import { RoomContext } from "../Context/RoomContext";
import { UserContext } from "../Context/UserContext";
import "./Game.css";

const Player = ({ name, avatar, stars, score }) => {
  const roomContext = useContext(RoomContext);
  const userContext = useContext(UserContext);

  const { cardsList, inactivePlayerCards, clientId, opponentId, turn } =
    roomContext;

  return (
    <div className="playerInfo">
      <div>
        <img
          className="avatar-frame"
          src="/images/avatar-frame.png"
          alt="avatar-frame"
        />
        <img
          style={{ marginLeft: "3px" }}
          className="player-image"
          // src="/images/player-avatar.jpg"
          src={avatar}
          alt="player-avatar"
        />
      </div>
      <h6 style={{ margin: "10px 0px 0px 2px" }}>{name}</h6>
      <h6
        style={{
          margin: "0px 0px 0px 2px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {stars &&
          stars.map((item, index) => {
            return (
              <p key={index} style={{ fontSize: "3.5vmin" }}>
                *
              </p>
            );
          })}
      </h6>
      <h6
        style={{
          margin: "-25px 0px 0px 2px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {`Score - ${score}`}
      </h6>
    </div>
  );
};

export default Player;
