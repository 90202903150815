export const fetchLeaderboardData = async (section, id) => {
  console.log(section, id);

  const userData = JSON.parse(localStorage.getItem('userData'));

  console.log(section, id);

  let axios = require('axios');

  let config = {
    method: 'get',
    // url: `https://oqjymf.us-east-vin.colyseus.net/leaderboard/${section}/${id}`,
    url: `${process.env.REACT_APP_BACKEND_URL}/leaderboard/${section}/${id}`,
    headers: { token: userData.gameAccessToken },
  };

  let res;
  let err;

  try {
    res = await axios(config);
  } catch (error) {
    err = error;
  }

  return res || err;
};
