import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { playerWin } from '../api/playerWin';
import { RoomContext } from '../Context/RoomContext';
import { usePlayer } from '../Context/PlayerContext';
import { initialStars, roundTwoCardSlot, cardSlot } from './Data';
import './Game.css';
import { updateCoins } from '../api/profile';

const ROUTES = {
  HOME: '/',
  WIN: '/youwin',
  LOSE: '/youlose',
};

const RoundTwoFinished = () => {
  const {
    score,
    room,
    setRoom,
    singlePlayer,
    selectedCategory,
    opponentScore,
    setScore,
    setOpponentScore,
    roundTwoCardsSequence,
  } = useContext(RoomContext);

  const navigate = useNavigate();
  const { player, updatePlayer } = usePlayer();
  const { user_id } = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (!room) navigate(ROUTES.HOME);

    window.onbeforeunload = () => '';

    return () => {
      window.onbeforeunload = null;
    };
  }, [room, navigate]);

  const resetGameState = () => {
    // Reset all necessary state here
    setRoom(null);
    // ... other state resets
  };

  const handleUpdateCoins = async () => {
    try {
      const response = await playerWin(score, selectedCategory);
      console.log(response);
    } catch (error) {
      console.error('Error updating coins:', error);
    }
  };

  const handleSeeResults = async () => {
    try {
      const res = await updateCoins(user_id, parseInt(score));
      if (res.status === 200) {
        await handleUpdateCoins();
      }
    } catch (error) {
      console.error('Error in playerWin:', error);
    }

    const navigateToResult = (path) => {
      navigate(path, { replace: true });
      setTimeout(() => {
        setScore(0);
        setOpponentScore(0);
      }, 500);
    };

    if (!singlePlayer) {
      navigateToResult(score > opponentScore ? ROUTES.WIN : ROUTES.LOSE);
    } else {
      navigateToResult(
        roundTwoCardsSequence === 'right' ? ROUTES.WIN : ROUTES.LOSE
      );
    }
  };

  return (
    <div>
      <Row style={{ marginRight: '0' }}>
        <Col />
        <Col lg={5} md={12} sm={12} style={{ paddingRight: '0' }}>
          <Container
            className={
              isMobile
                ? 'mobContainer alignCenter roundOneOverInnerContent'
                : 'roundTwoOverContainer alignCenter roundOneOverInnerContent'
            }
          >
            <Row>
              <Col>
                <div
                  style={{
                    marginTop: '-30vmin',
                    textAlign: 'center',
                    color: 'goldenrod',
                  }}
                >
                  <h1 style={{ fontSize: '7vmin' }}>TIME QUEST</h1>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '-15vmin',
              }}
            >
              <Col lg={6} md={12} sm={12}>
                <div className='roundOverBox'>
                  <h4
                    style={{
                      padding: '35px 3px 5px 3px',
                      fontSize: '3vmin',
                      color: 'white',
                    }}
                  >
                    ROUND 2: COMPLETED <br />
                    HOW DID <br />
                    YOU DO???
                  </h4>
                  <button
                    className='rounOverButtons'
                    style={{ padding: '5px', margin: '20px' }}
                    onClick={handleSeeResults}
                  >
                    See Results
                  </button>
                </div>
              </Col>
              <Col lg={6} />
            </Row>
          </Container>
        </Col>
        <Col />
      </Row>
    </div>
  );
};

export default RoundTwoFinished;
