import React, { useState, useContext } from "react";
import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

const LoginForm = () => {
  let userContext = useContext(UserContext);
  let navigate = useNavigate();

  const [error, setError] = useState(false);

  const {
    logIn,
    setLogIn,
    email,
    setEmail,
    userName,
    setUserName,
    setAvatar,
    password,
    setPassword,
  } = userContext;

  const notify = (res) => {
    if (res === "incomplete") {
      toast.error("Please enter complete details");
    } else if (res === "resErr") {
      toast.error("Something went wrong");
    }
  };

  const UserLogin = () => {
    // console.log(process.env.REACT_APP_COLYSEUSS_URL, "URLLLLLLLLLLLLLLLL");

    if (email && password) {
      const id = toast.loading("Logging In...");

      var axios = require("axios");
      var data = JSON.stringify({
        std_email: email,
        std_pass: password,
      });

      // var data = JSON.stringify({
      //   email,
      //   password,
      // });

      var config = {
        method: "post",
        // url: "https://oqjymf.us-east-vin.colyseus.net/login",
        // url: `${process.env.REACT_APP_PORTAL_LOGIN_URL}`,
        url: `${process.env.REACT_APP_BACKEND_URL}/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            setError(false);
            localStorage.setItem(
              "userData",
              JSON.stringify({
                id: response.data.std_id,
                name: response.data.std_name,
                email: response.data.std_email,
                avatar: response.data.profile_img,
                classId: response.data.classId,
                schoolId: response.data.schoolId,
                cityId: response.data.cityId,
                stateId: response.data.stateId,
                countryId: response.data.countryId,
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken,
              })
            );
            setEmail(response.data.std_email);
            setUserName(response.data.std_name);
            setAvatar(response.data.profile_img);
            toast.update(id, {
              render: "Login Successful",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            setTimeout(() => {
              navigate("/", { replace: true });
            }, 2500);
          } else {
            notify("resErr");
          }

          // if (response.status === 200) {
          //   setError(false);
          //   localStorage.setItem(
          //     "userData",
          //     JSON.stringify({
          //       id: response.data.content.user.id,
          //       name:
          //         response.data.content.user.first_name +
          //         response.data.content.user.last_name,
          //       email: response.data.content.user.email,
          //       avatar: response.data.content.user.avatar,
          //       classId: response.data.classId,
          //       schoolId: response.data.schoolId,
          //       cityId: response.data.cityId,
          //       stateId: response.data.stateId,
          //       countryId: response.data.content.user.country_id,
          //       accessToken: response.data.content.accessToken,
          //       refreshToken: response.data.refreshToken,
          //     })
          //   );
          //   setEmail(response.data.std_email);
          //   setUserName(response.data.std_name);
          //   setAvatar(response.data.profile_img);
          //   toast.update(id, {
          //     render: "Login Successful",
          //     type: "success",
          //     isLoading: false,
          //     autoClose: 5000,
          //   });
          //   setTimeout(() => {
          //     navigate("/", { replace: true });
          //   }, 2500);
          // } else {
          //   notify("resErr");
          // }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 401) {
            setError(true);
            toast.update(id, {
              render: "Incorrect Email or Password",
              type: "error",
              isLoading: false,
              autoClose: 2000,
            });
          }
        });
    } else {
      notify("incomplete");
    }
  };

  return (
    <div>
      <form
        className="loginForm"
        autoComplete="nope"
        onSubmit={(e) => {
          e.preventDefault();
          UserLogin();
        }}
      >
        <div style={{ width: "20%", margin: "15px auto" }}>
          <img style={{ width: "100%" }} src="/images/login-heading.png" />
        </div>
        <div className="loginFormInput">
          <input
            type="email"
            value={email}
            placeholder="Email"
            autoComplete="nope"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <br />
        <div className="loginFormInput">
          <input
            type="password"
            value={password}
            placeholder="Password"
            autoComplete="off"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {error && <p className="loginError">Incorrect Email or Password</p>}

        <button type="submit">
          <img style={{ width: "100%" }} src="/images/submit-btn.png" />
        </button>

        <button style={{ width: "35%" }}>
          <Link to="/forgot-password">
            <img style={{ width: "100%" }} src="/images/forgot-pass-btn.png" />
          </Link>
        </button>

        {/* <button style={{ width: "35%" }}>
          <img style={{ width: "100%" }} src="/images/new-acc-btn.png" />
        </button> */}
      </form>
      <ToastContainer />
    </div>
  );
};

export default LoginForm;
