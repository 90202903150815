import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";

import "./Game.css";
import { Form } from "react-bootstrap";

const Menu = () => {
  const [open, setOpen] = useState(false);

  const { logOut } = useContext(UserContext);

  const navigate = useNavigate();

  const handlePlayGame = () => {
    navigate("select-category", { replace: true });
  };

  const handleShowVideo = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLeaderboard = () => {
    navigate("/leaderboard", { replace: true });
  };

  const handleProfile = () => {
    navigate("/profile", { replace: true });
  };

  const handleLogout = () => {
    logOut();
  };

  // useEffect(() => {
  //   localStorage.setItem(
  //     "userData",
  //     JSON.stringify({
  //       accessToken:
  //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiaWF0IjoxNjcyMzgyMTQ3LCJleHAiOjE2NzI0Njg1NDd9.e6dMHpfsfA3DFkUa6j-ZAWP-d-w1OuLxFUfhm9ngZ68",
  //       avatar:
  //         "https://timequest.huntthepast.com/files/1556218087-112144-3-1.png-1658322152950.png",
  //       cityId: 4628,
  //       classId: 1,
  //       countryId: 1,
  //       email: "bilal@gmail.com",
  //       id: 2,
  //       name: "Muhammad Bilal",
  //       refreshToken:
  //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiaWF0IjoxNjcyMzgyMTQ3fQ.Lfj1mh7zvdJujltYckKtE2mtrSmSCkzp5xiOUDnxd0E",
  //       schoolId: 3,
  //       stateId: 1,
  //     })
  //   );
  // }, []);

  return (
    <div className="menuBg">
      <div className="menu-title">
        <img style={{ width: "40%" }} src="/images/Time-Quest.png" />
      </div>
      {open && (
        <div
          // width="50vw !important"
          className="modal show"
          style={{ display: "block", position: "absolute" }}
        >
          <Modal.Dialog style={{ minWidth: "600px", top: "100px" }}>
            <Modal.Header>
              <Modal.Title>Gameplay Tutorial</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <iframe
                width="567"
                height="315"
                src="https://www.youtube.com/embed/videoseries?list=PLXpHtrB3uJdpFXzpKB7QfMMVAFBdZ1dMf"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" width="100px" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
      <div className="menu-buttons">
        <button onClick={handlePlayGame}>Play The Game</button>
        <button onClick={handleShowVideo}>Video Tutorial</button>
        <button onClick={handleLeaderboard}>Leaderboard</button>
        <button onClick={handleProfile}>Profile</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default Menu;
