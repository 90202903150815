import React, { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Container, Row, Col } from "react-bootstrap";
import { RoomContext } from "../Context/RoomContext";
import { useNavigate } from "react-router-dom";
import { cardSlot } from "./Data";
import "./Game.css";

const RoundOneFinished = () => {
  let roomContext = useContext(RoomContext);
  const {
    room,
    turn,
    clientId,
    roundTwoStart,
    setRoundOne,
    setRoundTwo,
    setRound,
    setCardsList,
    setActivePlayerBoard,
    setInactivePlayerBoard,
  } = roomContext;

  let navigate = useNavigate();

  useEffect(() => {
    window.onbeforeunload = function () {
      return "";
    };
  }, []);

  useEffect(() => {
    if (!room) {
      navigate("/");
    }
  }, [room]);

  useEffect(() => {
    setRoundOne(false);
    setRoundTwo(true);
    setActivePlayerBoard([...cardSlot]);
    setInactivePlayerBoard([...cardSlot]);
  }, []);

  return (
    <div style={{ width: "100vw" }}>
      <Row style={{ marginRight: "0" }}>
        <Col style={{ margin: "0" }} />
        <Col lg={5} md={12} sm={12} style={{ paddingRight: "0" }}>
          <Container
            className={
              isMobile
                ? "mobContainer alignCenter roundOneOverInnerContent"
                : "roundOneOverContainer alignCenter roundOneOverInnerContent"
            }
          >
            <Row>
              <Col>
                <div
                  style={{
                    marginTop: "-30vmin",
                    textAlign: "center",
                    color: "goldenrod",
                  }}
                >
                  <h1 style={{ fontSize: "7vmin" }}>TIME QUEST</h1>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "-15vmin",
              }}
            >
              <Col lg={6} />
              <Col lg={6} md={12} sm={12}>
                <div
                  className="roundOverBox"
                  style={
                    {
                      // width: "110%",
                      // marginLeft: "260px",
                      // marginTop: "80px",
                      // textAlign: "center",
                      // backgroundColor: "#284086",
                      // opacity: "0.7",
                    }
                  }
                >
                  <h4
                    style={{
                      padding: "35px 3px 5px 3px",
                      fontSize: "3vmin",
                      color: "white",
                    }}
                  >
                    ROUND 1: COMPLETED <br />
                    TIME FOR SOME <br />
                    BONUS POINTS
                  </h4>
                  <button
                    className="rounOverButtons"
                    style={{
                      padding: "5px",
                      margin: "20px",
                    }}
                    onClick={() => {
                      // turn === clientId && roundTwoStart();
                      setRoundOne(false);
                      setRoundTwo(true);
                      setRound("roundTwo");
                      navigate("/game", { replace: true });
                      console.log("starting round two");
                    }}
                  >
                    Next Round
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col style={{ margin: "0" }} />
      </Row>
    </div>
  );
};

export default RoundOneFinished;
