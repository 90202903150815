export const cardSlot = [
  {
    id: "x1",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x2",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x3",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x4",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x5",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
];

export const roundTwoCardSlot = [
  {
    id: "x1",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x2",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x3",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x4",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
  {
    id: "x5",
    name: "cardSlot",
    url: "/images/card-slot.png",
  },
];

// export const dualInactiveList = [
//   {
//     id: "x1",
//     url: "/images/question-card.png",
//   },
//   {
//     id: "x2",
//     url: "/images/card-slot.png",
//   },
//   {
//     id: "x3",
//     url: "/images/question-card.png",
//   },
//   {
//     id: "x4",
//     url: "/images/card-slot.png",
//   },
//   {
//     id: "x5",
//     url: "/images/question-card.png",
//   },
// ];

// export const leftInactiveList = [
//   {
//     id: "x1",
//     url: "/images/question-card.png",
//   },
//   {
//     id: "x2",
//     url: "/images/card-slot.png",
//   },
//   {
//     id: "x3",
//     url: "/images/question-card.png",
//   },
//   {
//     id: "x4",
//     url: "/images/card-slot.png",
//   },
//   {
//     id: "x5",
//     url: "/images/card-slot.png",
//   },
// ];

// export const rightInactiveList = [
//   {
//     id: "x1",
//     url: "/images/card-slot.png",
//   },
//   {
//     id: "x2",
//     url: "/images/card-slot.png",
//   },
//   {
//     id: "x3",
//     url: "/images/question-card.png",
//   },
//   {
//     id: "x4",
//     url: "/images/card-slot.png",
//   },
//   {
//     id: "x5",
//     url: "/images/question-card.png",
//   },
// ];

export const cardList = [
  {
    id: 1,
    name: "Card 1",
    url: "/images/card1.png",
    date: "1-1-2022",
    category: "alpha",
  },
  {
    id: 2,
    name: "Card 2",
    url: "/images/card2.png",
    date: "4-2-2022",
    category: "alpha",
  },
  {
    id: 3,
    name: "Card 3",
    url: "/images/card3.png",
    date: "1-1-2021",
    category: "alpha",
  },
  {
    id: 4,
    name: "Card 4",
    url: "/images/card4.png",
    date: "3-15-2022",
    category: "alpha",
  },
  {
    id: 5,
    name: "Card 5",
    url: "/images/card5.png",
    date: "5-20-2021",
    category: "alpha",
  },
];

// export const extraCards = [
//   {
//     id: 6,
//     name: "Card 6",
//     url: "/images/card6.png",
//     date: "3-25-2022",
//     category: "alpha",
//   },
//   {
//     id: 7,
//     name: "Card 7",
//     url: "/images/card7.png",
//     date: "5-6-2021",
//     category: "alpha",
//   },
//   {
//     id: 8,
//     name: "Card 8",
//     url: "/images/card8.png",
//     date: "11-23-2021",
//     category: "alpha",
//   },
//   {
//     id: 9,
//     name: "Card 9",
//     url: "/images/card9.png",
//     date: "3-6-2022",
//     category: "alpha",
//   },
//   {
//     id: 10,
//     name: "Card 10",
//     url: "/images/card10.png",
//     date: "2-19-2022",
//     category: "alpha",
//   },
// ];

// export const rightOrderList = [
//   {
//     id: 3,
//     url: "/images/card3.png",
//   },
//   {
//     id: 5,
//     url: "/images/card5.png",
//   },
//   {
//     id: 1,
//     url: "/images/card1.png",
//   },
//   {
//     id: 4,
//     url: "/images/card4.png",
//   },
//   {
//     id: 2,
//     url: "/images/card2.png",
//   },
// ];

// export const refCard = [
//   {
//     id: 6,
//     url: "/images/card6.png",
//     date: "1-1-2022",
//   },
// ];

// export const leftSlot = [
//   {
//     id: "x6",
//     url: "/images/card-slot.png",
//   },
// ];

export const emptyCard = {
  id: "emptyCard",
  name: "emptyCard",
  url: "/images/card-slot.png",
};

export const initialStars = [
  { name: "star" },
  { name: "star" },
  { name: "star" },
  { name: "star" },
  { name: "star" },
];
