import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Card from "./Card";
import CardSlot from "./CardSlot";
import RefCards from "./RefCards";

import { Row, Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Game.css";

import { RoomContext } from "../Context/RoomContext";

import { cardList, cardSlot, emptyCard, roundTwoCardSlot } from "./Data";

const _ = require("lodash");

const RoundTwo = ({ roundTwo }) => {
  let roomContext = useContext(RoomContext);
  let navigate = useNavigate();

  const {
    onRoundTwoDrop,
    roundTwoCardList,
    setRoundTwoCardList,
    roundTwoBoard,
    setRoundTwoBoard,
    singlePlayer,
    changeTurn,
    // roundTwoChangeTurn,
    setRound,
    score,
    setScore,
    cardCounter,
    setCardCounter,
    setCardsList,
    setWin,
    opponentJoined,
    startRoundTwo,
    inactivePlayerBoard,
    setActivePlayerBoard,
    setInactivePlayerBoard,
    setInactivePlayerCards,
    roundTwoFinished,
    setRoundTwoFinished,
    lastTurn,
    setLastTurn,
    roundTwoCompletedByPlayerOne,
    roundTwoCompletedByPlayerTwo,
    setRoundTwoCardsSequence,
  } = roomContext;

  const notify = (response) => {
    if (response === "turnChanged") {
      toast.success("Opponent's Turn Now");
    } else if (response === "rightDrop") {
      toast.success("Right Drop");
    } else if (response === "wrongDrop") {
      toast.warning("Wrong Drop");
    } else if (response === "left") {
      toast.warning("Opponent Left");
    } else {
      toast.error("");
    }
  };

  // const [board, setBoard] = useState([...roundTwoBoard]);
  const [roundTwoCards, setRoundTwoCards] = useState();
  const [dropedCardIndex, setDropedCardIndex] = useState();
  const [wrongMoveCounter, setWrongMoveCounter] = useState(0);
  // const [cardCounter, setCardCounter] = useState(0);
  const [wrongDropsList, setWrongDropsList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [modalInfo, setModalInfo] = useState({});
  const [insertCard, setInsertCard] = useState(false);
  const [sortedCards, setSortedCards] = useState();
  const [showCardDate, setShowCardDate] = useState(false);
  // const [newCards, setNewCards] = useState(extraCards);
  // const [insertCard, setInsertCard] = useState(false);
  // const [score, setScore] = useState(0);

  const [hover, setHover] = useState(false);
  const [hoverIndex, setHoverIndex] = useState();
  const [onHoverIndex, setOnHoverIndex] = useState();

  useEffect(() => {
    if (roundTwoCardList.length > 0) {
      setRoundTwoCards([...roundTwoCardList]);
      setLoaded(true);
    }
  }, [startRoundTwo]);

  useEffect(() => {
    setRound("roundTwo");
    setRoundTwoBoard([...cardSlot]);
    const sortedCardsByDate = roundTwoCardList.sort((a, b) => a.date - b.date);
    setSortedCards(sortedCardsByDate);
  }, []);

  console.log("SORTED_CARDS", sortedCards);

  // useEffect(() => {
  //   if (roundTwoBoard && roundTwoCards) {
  //     setInactivePlayerBoard(roundTwoBoard);
  //     setInactivePlayerCards(roundTwoCards);
  //   }
  // }, [roundTwoBoard]);

  // useEffect(() => {
  //   if (roundTwoBoard && roundTwoCards) {
  //     onRoundTwoDrop(roundTwoBoard, roundTwoCards, score);
  //   }
  // }, [roundTwoCards, roundTwoBoard]);

  useEffect(() => {
    if (roundTwoFinished === true) {
      navigate("/roundtwoover", { replace: true });
      setTimeout(() => {
        setRoundTwoFinished(false);
        setLastTurn(false);
      }, 2000);
    }
  }, [roundTwoFinished]);

  // useEffect(() => {
  //   if (roundTwoCardList) {
  //     setRoundTwoCards(roundTwoCardList);
  //   }
  // }, []);

  console.log("sortedCards", sortedCards);

  const onHover = (dragedCard, monitor, cardHoverIndex) => {
    setOnHoverIndex(cardHoverIndex);
  };

  const wrongCard = roundTwoBoard.filter(
    (item) => item.id === wrongDropsList[0]
  );
  const wrongCardIndex = roundTwoBoard.findIndex(
    (item) => item?.id === wrongCard[0]?.id
  );

  console.log("wrongCard", wrongCard, wrongDropsList);

  useEffect(() => {
    let checkEmptyCard = roundTwoBoard.findIndex(
      (item) => item?.id === emptyCard?.id
    );
    console.log(roundTwoBoard);
    if (!hover) {
      setRoundTwoBoard(
        roundTwoBoard.filter((card) => card?.id !== emptyCard.id)
      );
    } else {
      let left = onHoverIndex - 1;
      let right = onHoverIndex + 1;
      let wrongCardName = wrongCard[0]?.name;
      let hoverName;
      let leftCardName;
      let rightCardName;

      if (left >= 0) {
        leftCardName = roundTwoBoard[left].name;
      }
      if (right < roundTwoBoard.length) {
        rightCardName = roundTwoBoard[right].name;
      }
      if (roundTwoBoard[onHoverIndex]) {
        hoverName = roundTwoBoard[onHoverIndex].name;
      }

      console.log(leftCardName);
      console.log(rightCardName);
      console.log(wrongCardName);
      console.log(hoverName);
      console.log(checkEmptyCard);
      console.log(onHoverIndex);

      if (hoverName === "cardSlot") {
        setRoundTwoBoard(
          roundTwoBoard.filter((card) => card.id !== emptyCard.id)
        );
      }

      if (checkEmptyCard !== -1) {
        if (hoverName !== wrongCardName) {
          if (
            onHoverIndex > checkEmptyCard &&
            leftCardName !== wrongCardName &&
            hoverName !== wrongCardName
          ) {
            roundTwoBoard.splice(checkEmptyCard, 1);
            roundTwoBoard.splice(left, 0, { ...emptyCard });
          } else if (
            onHoverIndex < checkEmptyCard &&
            rightCardName !== wrongCardName &&
            hoverName !== wrongCardName
          ) {
            roundTwoBoard.splice(checkEmptyCard, 1);
            roundTwoBoard.splice(right, 0, { ...emptyCard });
          } else {
            return;
          }
        } else if (hoverName === wrongCardName) {
          console.log("hovering on wrong card");
          setRoundTwoBoard(
            roundTwoBoard.filter((card) => card.id !== emptyCard.id)
          );
        }
      }

      if (checkEmptyCard === -1) {
        if (onHoverIndex < wrongCardIndex) {
          if (
            hoverName !== "emptyCard" &&
            hoverName !== "cardlot" &&
            leftCardName !== wrongCardName &&
            hoverName !== wrongCardName
          ) {
            if (leftCardName && rightCardName && hoverName) {
              if (leftCardName !== "cardSlot" && hoverName !== "cardSlot") {
                roundTwoBoard.splice(onHoverIndex, 0, { ...emptyCard });
              }
            }
          }
        } else {
          if (
            hoverName !== "emptyCard" &&
            hoverName !== "cardlot" &&
            leftCardName !== wrongCardName &&
            rightCardName !== wrongCardName &&
            hoverName !== wrongCardName
          ) {
            console.log("here");

            if (leftCardName && hoverName) {
              if (leftCardName !== "cardSlot" && hoverName !== "cardSlot") {
                roundTwoBoard.splice(onHoverIndex, 0, { ...emptyCard });
              }
            } else if (hoverName) {
              // if (rightCardName && hoverName !== "cardlot") {
              //   console.log("hovering on most left card");
              //   roundTwoBoard.splice(onHoverIndex, 0, { ...emptyCard });
              // } else if (leftCardName && hoverName !== "cardlot") {
              //   console.log(hoverName);
              //   console.log("hovering on most right card");
              //   roundTwoBoard.splice(right, 0, { ...emptyCard });
              //   // roundOneBoard.push({ ...emptyCard });
              // }
            }
          }
        }
      }
    }
  }, [onHoverIndex, hover]);

  const onCardHover = (id) => {
    const index = roundTwoBoard.findIndex((card) => card.id === id);
    setHoverIndex(index);
  };

  const addImageToBoard = (dropedCard) => {
    let dropedCardIndex = roundTwoBoard.findIndex(
      (item) => item.id === emptyCard.id
    );

    const rightDropIndex = sortedCards?.findIndex(
      (card) => card.id === dropedCard?.id
    );

    if (
      roundTwoBoard[dropedCardIndex] &&
      roundTwoBoard[dropedCardIndex].name !== "cardSlot"
    ) {
      roundTwoBoard[dropedCardIndex] = { ...dropedCard };
      roundTwoBoard.splice(wrongCardIndex, 1);
      roundTwoBoard.map((card, index) => {
        if (card.name === "cardSlot") {
          return (roundTwoBoard[index] = cardSlot[index]);
        } else {
          return card;
        }
      });
      setRoundTwoBoard([...roundTwoBoard]);

      if (dropedCardIndex === rightDropIndex) {
        notify("rightDrop");
        setWrongDropsList([]);
        setWrongMoveCounter(0);
        setScore(score + 10);
      } else {
        notify("wrongDrop");
        setWrongDropsList([]);
        setWrongMoveCounter(0);
        setTimeout(() => {
          let sortedArray = cardSlot;
          roundTwoBoard.map((card) => {
            const rightIndex = sortedCards.findIndex(
              (item) => item.id === card.id
            );
            if (rightIndex >= 0) {
              sortedArray[rightIndex] = { ...card };
            }
          });

          console.log(sortedArray);
          setRoundTwoBoard([...sortedArray]);
        }, 1000);
      }
    }
  };

  const addImageToCard = (dropedCard, monitor, id) => {
    const index = roundTwoCards.findIndex((card) => card.id === dropedCard.id);
    const rightDropIndex = sortedCards?.findIndex(
      (card) => card.id === dropedCard?.id
    );

    console.log(rightDropIndex);
    const checkCount = () => {
      // onRoundTwoDrop(roundTwoBoard, roundTwoCards, score);

      if (cardCounter >= 4) {
        setShowCardDate(true);
        setWrongMoveCounter(0);

        let finalBoardArray = roundTwoBoard.filter(
          (item) => item.name !== "cardSlot"
        );

        console.log("final Array", finalBoardArray);

        let boardDates = finalBoardArray.map((board) =>
          new Date(board.date).getTime()
        );
        console.log("boardDates", boardDates);

        let sortedDates = finalBoardArray
          .map((board) => new Date(board.date).getTime())
          .sort((a, b) => a - b);
        console.log("sortedDates", sortedDates);

        if (
          boardDates[0] === sortedDates[0] &&
          boardDates[1] === sortedDates[1] &&
          boardDates[2] === sortedDates[2] &&
          boardDates[3] === sortedDates[3] &&
          boardDates[4] === sortedDates[4]
        ) {
          // setWin(true);

          setTimeout(() => {
            // const finalScore = score + 25;
            // setScore(finalScore);

            if (!singlePlayer) {
              if (lastTurn) {
                roundTwoCompletedByPlayerTwo(score);
              } else if (!lastTurn) {
                roundTwoCompletedByPlayerOne(score);
                toast.loading("Waiting for opponent to finish the game");
              }
            } else {
              setRoundTwoCardsSequence("right");
              setTimeout(() => {
                setRoundTwoFinished(true);
              }, 3000);
            }

            // lastTurn
            //   ? roundTwoCompletedByPlayerTwo(finalScore)
            //   : changeTurn(roundTwoCards, roundTwoBoard);
          }, 1500);
        } else {
          if (!singlePlayer) {
            setTimeout(() => {
              if (lastTurn) {
                roundTwoCompletedByPlayerTwo(score);
              } else if (!lastTurn) {
                roundTwoCompletedByPlayerOne(score);
                toast.loading("Waiting for opponent to finish the game");
              }

              // !lastTurn &&
              //   roundTwoCompletedByPlayerOne(score) &&
              //   toast.loading("Waiting for opponent to finish the game");

              // lastTurn && roundTwoCompletedByPlayerTwo(score);

              // lastTurn
              //   ? roundTwoCompletedByPlayerTwo(score)
              //   : changeTurn(roundTwoCards, roundTwoBoard);
            }, 1500);
          } else {
            setRoundTwoCardsSequence("wrong");
            setTimeout(() => {
              setRoundTwoFinished(true);
            }, 3000);
          }
        }

        setWrongDropsList([]);
      } else {
        // !lastTurn && notify("turnChanged");
        // setTimeout(() => {
        //   !lastTurn && changeTurn(roundTwoCards, roundTwoBoard);
        // }, 500);
      }
    };

    console.log(id);

    if (id === "x1") {
      if (roundTwoBoard[0].id === "x1") {
        setCardCounter(cardCounter + 1);
        setDropedCardIndex(0);
        roundTwoBoard[0] = dropedCard;
        roundTwoCards.splice(index, 1);
        setCardsList([...roundTwoCards]);
        checkCount(roundTwoBoard, roundTwoCards);
        if (rightDropIndex === 0) {
          notify("rightDrop");
          setScore(score + 10);
        } else {
          // setWrongDropsList([...wrongDropsList, 0]);
          setWrongDropsList([roundTwoBoard[0].id]);
          setWrongMoveCounter(1);
          notify("wrongDrop");
        }
      }
    } else if (id === "x2") {
      if (roundTwoBoard[1].id === "x2") {
        setCardCounter(cardCounter + 1);
        setDropedCardIndex(1);
        roundTwoBoard[1] = dropedCard;
        roundTwoCards.splice(index, 1);
        setCardsList([...roundTwoCards]);
        checkCount(roundTwoBoard, roundTwoCards);
        if (rightDropIndex === 1) {
          notify("rightDrop");
          setScore(score + 10);
        } else {
          // setWrongDropsList([...wrongDropsList, 1]);
          setWrongDropsList([roundTwoBoard[1].id]);
          setWrongMoveCounter(1);
          notify("wrongDrop");
        }
      }
    } else if (id === "x3") {
      if (roundTwoBoard[2].id === "x3") {
        setCardCounter(cardCounter + 1);
        setDropedCardIndex(2);
        roundTwoBoard[2] = dropedCard;
        roundTwoCards.splice(index, 1);
        setCardsList([...roundTwoCards]);
        checkCount(roundTwoBoard, roundTwoCards);
        if (rightDropIndex === 2) {
          notify("rightDrop");
          setScore(score + 10);
        } else {
          // setWrongDropsList([...wrongDropsList, 2]);
          setWrongDropsList([roundTwoBoard[2].id]);
          setWrongMoveCounter(1);
          notify("wrongDrop");
        }
      }
    } else if (id === "x4") {
      if (roundTwoBoard[3].id === "x4") {
        setCardCounter(cardCounter + 1);
        setDropedCardIndex(3);
        roundTwoBoard[3] = dropedCard;
        roundTwoCards.splice(index, 1);
        setCardsList([...roundTwoCards]);
        checkCount(roundTwoBoard, roundTwoCards);
        if (rightDropIndex === 3) {
          notify("rightDrop");
          setScore(score + 10);
        } else {
          // setWrongDropsList([...wrongDropsList, 3]);
          setWrongDropsList([roundTwoBoard[3].id]);
          setWrongMoveCounter(1);
          notify("wrongDrop");
        }
      }
    } else if (id === "x5") {
      if (roundTwoBoard[4].id === "x5") {
        setCardCounter(cardCounter + 1);
        setDropedCardIndex(4);
        roundTwoBoard[4] = dropedCard;
        roundTwoCards.splice(index, 1);
        setCardsList([...roundTwoCards]);
        checkCount(roundTwoBoard, roundTwoCards);
        if (rightDropIndex === 4) {
          notify("rightDrop");
          setScore(score + 10);
        } else {
          // setWrongDropsList([...wrongDropsList, 4]);
          setWrongDropsList([roundTwoBoard[4].id]);
          setWrongMoveCounter(1);
          notify("wrongDrop");
        }
      }
    } else {
      return;
    }

    setRoundTwoBoard([...roundTwoBoard]);
    setRoundTwoCards([...roundTwoCards]);
    setRoundTwoCardList([...roundTwoCards]);
  };

  const moveCardToRightSpot = (dropedCard) => {
    const currentIndex = roundTwoBoard.findIndex(
      (card) => card.id === dropedCard.id
    );
    const rightIndex = sortedCards.findIndex(
      (card) => card.id === dropedCard.id
    );
    console.log("movingCard", currentIndex, rightIndex, dropedCard);
    roundTwoBoard.splice(currentIndex, 1, { ...cardSlot[currentIndex] });
    roundTwoBoard.splice(rightIndex, 1, { ...dropedCard });
    console.log(roundTwoBoard);
    setRoundTwoBoard([...roundTwoBoard]);
  };

  const moveWrongCard = (dropedCard, monitor, id) => {
    if (wrongMoveCounter > 0) {
      const dropedCardIndex = roundTwoBoard.findIndex(
        (item) => item.id === dropedCard.id
      );

      const rightDropIndex = sortedCards?.findIndex(
        (card) => card.id === dropedCard?.id
      );

      console.log(rightDropIndex);

      const updateWrongDropsList = (newIndex) => {
        // const updatedWrongDrops = wrongDropsList.filter(
        //   (item) => item !== dropedCardIndex
        // );
        // setWrongDropsList([newIndex]);
        setWrongDropsList([]);
      };

      // const checkCard = wrongDropsList.find((item) => item === dropedCardIndex);

      // console.log(updatedWrongDrops);
      console.log(dropedCardIndex);
      console.log(id);

      if (id === "x1") {
        if (roundTwoBoard[0].id === "x1") {
          roundTwoBoard[0] = dropedCard;
          roundTwoBoard[dropedCardIndex] = cardSlot[dropedCardIndex];
          updateWrongDropsList();
          if (rightDropIndex === 0) {
            notify("rightDrop");
            setScore(score + 5);
          } else {
            // updateWrongDropsList(0);
            notify("wrongDrop");
            setTimeout(() => {
              moveCardToRightSpot(dropedCard);
            }, 1000);
          }
        }
      } else if (id === "x2") {
        if (roundTwoBoard[1].id === "x2") {
          roundTwoBoard[1] = dropedCard;
          roundTwoBoard[dropedCardIndex] = cardSlot[dropedCardIndex];
          updateWrongDropsList();
          if (rightDropIndex === 1) {
            notify("rightDrop");
            setScore(score + 5);
          } else {
            // updateWrongDropsList(1);
            notify("wrongDrop");
            setTimeout(() => {
              moveCardToRightSpot(dropedCard);
            }, 1000);
          }
        }
      } else if (id === "x3") {
        if (roundTwoBoard[2].id === "x3") {
          roundTwoBoard[2] = dropedCard;
          roundTwoBoard[dropedCardIndex] = cardSlot[dropedCardIndex];
          updateWrongDropsList();
          if (rightDropIndex === 2) {
            notify("rightDrop");
            setScore(score + 5);
          } else {
            // updateWrongDropsList(2);
            notify("wrongDrop");
            setTimeout(() => {
              moveCardToRightSpot(dropedCard);
            }, 1000);
          }
        }
      } else if (id === "x4") {
        if (roundTwoBoard[3].id === "x4") {
          roundTwoBoard[3] = dropedCard;
          roundTwoBoard[dropedCardIndex] = cardSlot[dropedCardIndex];
          updateWrongDropsList();
          if (rightDropIndex === 3) {
            notify("rightDrop");
            setScore(score + 5);
          } else {
            // updateWrongDropsList(3);
            notify("wrongDrop");
            setTimeout(() => {
              moveCardToRightSpot(dropedCard);
            }, 1000);
          }
        }
      } else if (id === "x5") {
        if (roundTwoBoard[4].id === "x5") {
          roundTwoBoard[4] = dropedCard;
          roundTwoBoard[dropedCardIndex] = cardSlot[dropedCardIndex];
          updateWrongDropsList();
          if (rightDropIndex === 4) {
            notify("rightDrop");
            setScore(score + 5);
          } else {
            // updateWrongDropsList(4);
            notify("wrongDrop");
            setTimeout(() => {
              moveCardToRightSpot(dropedCard);
            }, 1000);
          }
        }
      } else {
        return;
      }

      setWrongMoveCounter(0);
      setRoundTwoBoard([...roundTwoBoard]);
    }
  };
  console.log(wrongDropsList);
  console.log(cardCounter);
  console.log(roundTwoBoard);
  return (
    <>
      {loaded ? (
        <>
          <h1 className="round" style={{ marginBottom: "3vmin" }}>
            Round 2
          </h1>

          <RefCards
            roundTwoBoard={roundTwoBoard}
            roundTwoCards={roundTwoCards}
            onHover={onHover}
            onCardHover={onCardHover}
            hover={hover}
            hoverIndex={hoverIndex}
            setHover={setHover}
            moveWrongCard={moveWrongCard}
            addImageToBoard={addImageToBoard}
            addImageToCard={addImageToCard}
            // addImageToCard={addImageToCard}
            wrongDropsList={wrongDropsList}
            showCardDate={showCardDate}
            dropedCardIndex={dropedCardIndex}
            // roundOne={roundOne}
            roundTwo={roundTwo}
          />
          {/* <div className="card-slot" style={{ top: "0" }}>
              {roundTwoBoard.map((picture, index) => {
                return (
                  <CardSlot
                    key={picture.id}
                    id={picture.id}
                    index={index}
                    name={picture.name}
                    url={picture.url}
                    date={picture.date}
                    cardDrop={picture.canDrop}
                    board={roundTwoBoard}
                    addImageToBoard={addImageToBoard}
                    moveWrongCard={moveWrongCard}
                    showCardDate={showCardDate}
                    roundTwo={roundTwo}
                    wrongDropsList={wrongDropsList}
                    roundTwoBoard={roundTwoBoard}
                    roundTwoCards={roundTwoCards}
                    dropedCardIndex={dropedCardIndex}
                  />
                );
              })}
            </div> */}

          <div
            style={{
              height: "22vmin",
              // marginTop: "60px",
              marginBottom: "4vmin",
            }}
          >
            <h2
              className="title"
              style={{ marginTop: "20px", marginBottom: "2vmin" }}
            >
              Place your cards in correct order
            </h2>

            <div className="card-slot" style={{ marginBottom: "5px" }}>
              {roundTwoCards &&
                roundTwoCards.map((picture, index) => {
                  return (
                    <Card
                      key={picture.id}
                      id={picture.id}
                      name={picture.name}
                      url={picture.url}
                      date={picture.date}
                      dateType={picture?.dateType}
                      canDrop={picture.canDrop}
                      index={index}
                      roundTwoCards={roundTwoCards}
                      // insertCard={insertCard}
                    />
                  );
                })}
            </div>
          </div>
          <ToastContainer autoClose={2000} />
        </>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ height: "40vh" }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
    </>
  );
};

export default RoundTwo;
