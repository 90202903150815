export const ForgotPassword = async email => {
  const axios = require("axios");

  let res;

  try {
    const data = {
      email,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/forgetpassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    res = await axios(config);
  } catch (error) {
    console.log(error);
    throw error;
  }

  return res;
};
