import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchUsersScore,
  fetchTotalCollectCoins,
  fetchTotalCollectedPointsByCategory,
  fetchTotalTimePlayedGame,
} from "../api/profile";
import "./Game.css";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  //   const [leaderboardData, setLeaderboardData] = useState();
  const [profileData, setProfileData] = useState();
  const [activeTab, setActiceTab] = useState("score");
  // const [coinTag, setCoinTag] = useState("coin");
  // const [pointTag, setPointTag] = useState("point");
  // const [playTimeTag, setPlayTimeTag] = useState("play");

  const { schoolId, cityId, stateId } = JSON.parse(
    localStorage.getItem("userData")
  );

  const navigate = useNavigate();

  //   useEffect(async () => {
  //     fetchLeaderboardData("school", schoolId)
  //       .then((res) => {
  //         if (res) {
  //           if (res.status === 200) {
  //             console.log(res);
  //             let schoolData = [];
  //             console.log(res.data);
  //             res.data.map((item) => {
  //               console.log(item.studentData.studentDetails);
  //               schoolData = [
  //                 ...schoolData,
  //                 { ...item.studentData.studentDetails },
  //               ];
  //             });
  //             schoolData.sort((a, b) => b.studentScores - a.studentScores);
  //             console.log(schoolData);
  //             setLeaderboardData(schoolData);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //           }
  //         }
  //       })
  //       .catch(() => {});
  //   }, []);

  const handleScores = async () => {
    setLoading(true);
    setActiceTab("score");
    try {
      const res = await fetchUsersScore();
      setProfileData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    handleScores();
  }, []);

  const handleCoins = async () => {
    setLoading(true);
    setActiceTab("coins");
    try {
      const res = await fetchTotalCollectCoins();
      setProfileData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePoints = async () => {
    setLoading(true);
    setActiceTab("points");
    try {
      const res = await fetchTotalCollectedPointsByCategory();
      console.log(res.data, "sbsjbsbsbsk");
      setProfileData(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlayTime = async () => {
    setLoading(true);
    setActiceTab("time");
    try {
      const res = await fetchTotalTimePlayedGame();
      setProfileData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(profileData);

  return (
    <>
      <div className="leaderboardContainer">
        <div className="leaderboardBox">
          <div className="leadboardAlign">
            <div className="leadboardHeader">
              <h1>PROFILE</h1>
            </div>
            <div className="leaderboardCategories">
              <div className="leaderboardCategoryItem" onClick={handleScores}>
                <p>Scores</p>
              </div>
              <div className="leaderboardCategoryItem" onClick={handleCoins}>
                <p>Coins</p>
              </div>
              <div className="leaderboardCategoryItem" onClick={handlePoints}>
                <p>Points</p>
              </div>
              <div className="leaderboardCategoryItem" onClick={handlePlayTime}>
                <p>Play Time</p>
              </div>
            </div>

            {loading ? (
              <div
                className="d-flex justify-content-center"
                style={{ height: "40vh" }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : profileData?.length > 0 ? (
              <div className="d-flex justify-content-center">
                <div className="leaderboardScores">
                  {profileData &&
                    profileData.map((item, index) => {
                      // const { studentData } = item;
                      // const { studentDetails } = studentData;
                      // console.log(studentDetails);
                      // console.log(studentDetails.dataValues);
                      return activeTab === "points" ? (
                        <div className="leaderboardScoreItemPoints" key={index}>
                          <div className="leaderboardScoreCountPoints">
                            {index + 1}
                          </div>

                          <div
                            className="leaderboardScoreContentPoints"
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.username}
                          </div>
                          <div
                            className="leaderboardScoreContentPoints"
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.name}
                          </div>

                          <div className="leaderboardScoreContentPoints">
                            {activeTab === "score"
                              ? item?.score
                              : activeTab === "coins"
                              ? item?.coins
                              : activeTab === "points"
                              ? item?.total
                              : item?.game_time}
                          </div>
                        </div>
                      ) : (
                        <div className="leaderboardScoreItem" key={index}>
                          <div className="leaderboardScoreCount">
                            {index + 1}
                          </div>
                          <div
                            className="leaderboardScoreContent"
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.name || item?.username}
                          </div>
                          {/* {item?.name && (
                            <div
                              className="leaderboardScoreContent"
                              style={{ marginLeft: "10px" }}
                            >
                              {item.name}
                            </div>
                          )} */}
                          <div className="leaderboardScoreContent">
                            {
                              // item?.studentScores ||
                              // item?.total ||
                              // || item?.score
                              // ||
                              // item?.game_time
                              activeTab === "score"
                                ? item?.score
                                : activeTab === "coins"
                                ? item?.coins
                                : // : tag === "points"
                                  // ? item?.total
                                  item?.game_time
                              // tag == "score" && item?.score
                            }
                          </div>
                          {/* <div className="leaderboardScoreContent">
                            {
                              item?.username
                            }
                          </div> */}
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="leaderboardScores">
                  <h2>No Data Found</h2>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <button
            className="leaderboadr-btn"
            onClick={() => (window.location.href = "/")}
          >
            Menu
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;
